export function formatZero (num, len) {
	if (String(num).length > len) return num;
	return (Array(len).join(0) + num).slice(-len);
}

/**
 * 方法一：js字符串切割
 * @param {*} str
 */
export function firstToUpper(str) {
	return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase());
}
