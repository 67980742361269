// import { firstToUpper } from "../utils/function";

import BasicLayout from "@/components/BasicLayout/index";

/**
 * 自动化工程
 * 参数一:目录
 * 参数二:是否查找自己目录
 * 参数三:指定查找文件
 */
const files = require.context('../views', true, /\.vue$/);
// 声明组件对象
const router = [];

const basic = {
    path: '/',
    component: BasicLayout,
    redirect: '/index',
    hidden: true,
    children: []
};

router.push(basic);

files.keys().map(item => {
    let pregFileName = item.match(/\.\/(.*)(index)?\.vue$/)
    let filePath = pregFileName[1];
    let path = filePath.split('/index')[0].toLowerCase()
    let pathArr = filePath.split('/')
    let name = pathArr.splice(-2, 1)[0]
    basic.children.push({
        path,
        name,
        component: () => import(`@/views/${filePath}`)
    })
})

export default router
