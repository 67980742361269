import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'

import ElementUI from 'element-ui'
Vue.use(ElementUI)

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {ak: 'gkujGgIofZKUyimNl4214G04nXeIf4M6'})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
