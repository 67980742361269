import storage from 'store'
import {login, getInfo, logout} from '@/api/login'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import {isNotEmpty} from "@/utils/util";

const user = {
    state: {
        userId: '',
        userName: '',
        orgId: '',
        token: '',
        name: '',
        avatar: '',
        roles: [],
        buttons: [], // 按钮权限
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, {name}) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_BUTTONS: (state, buttons) => {
            state.buttons = buttons
        },
        SET_USERID: (state, userId) => {
            state.userId = userId
        },
        SET_USERNAME: (state, userName) => {
            state.userName = userName
        },
        SET_ORGID: (state, orgId) => {
            state.orgId = orgId
        },
        SET_ORGTYPE: (state, orgType) => {
            state.orgType = orgType
        }
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    const result = response
                    storage.set(ACCESS_TOKEN, result.token, 12 * 60 * 60 * 1000)
                    commit('SET_TOKEN', result.token)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getInfo().then(response => {
                    const result = response
                    console.log(result)
                    if (isNotEmpty(result.userId)) {
                        commit('SET_ROLES', result.roleIds)
                        commit('SET_BUTTONS', result.buttons)
                        commit('SET_USERID', result.userId)
                        commit('SET_USERNAME', result.userName)
                        commit('SET_ORGID', result.orgId)
                        commit('SET_ORGTYPE', result.orgType)
                        commit('SET_INFO', result)
                    } else {
                        reject(new Error('getInfo: roles must be a non-null array !'))
                    }
                    // console.log(result)
                    commit('SET_NAME', {name: result.userName})
                    commit('SET_AVATAR', result.avatar || '/avatar2.jpg')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        Logout({commit, state}) {
            console.log(2)
            return new Promise((resolve) => {
                logout(state.token).then(() => {
                    resolve()
                }).catch(() => {
                    resolve()
                }).finally(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_INFO', {})
                    storage.remove(ACCESS_TOKEN)
                })
            })
        }

    }
}

export default user
