<template>
  <div class="main">
    <div class="img_wrap">
      <img src="@/assets/images/cusotmer/banner.jpg" alt="">
      <div class="fonts wrap_in">
        <h1>客户成功服务</h1>
        <p>给予SaaS商业模式</p>
        <p>我们致力于为安保企业提供好的服务体验</p>
        <p>解决安保企业全面管理的挑战，助力安保企业获得成功</p>
      </div>
    </div>
    <div class="wrap_in wrap_c_01">
      <h2>鹰能客户成功服务核心构成</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="item of [1,2,3]" :key="item">
          <a href="###" class="fy">
            <div class="top fx">
              <div class="circular fx">
                <img :src="require('@/assets/images/cusotmer/guest_icon'+item+'.png')" alt="">
              </div>
            </div>
            <span>专业实施服务</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="wrap_c_02">
      <div class="wrap_in">
        <h2>专业实施服务</h2>
        <ul class="serve_tab fx">
          <li :class="{active:serveTab===0}" @click="clickServeTab(0)"><a href="javascript:;">基于解决方案的测评实施服务</a></li>
          <li :class="{active:serveTab===1}" @click="clickServeTab(1)"><a href="javascript:;">基于成功实践的软件实施服务</a></li>
        </ul>
        <div class="wrap_ser">
          <ul class="mobile">
            <li class="mobile mobile100" v-for="item of serveData[serveTab]" :key="item.icon">
              <div class="image fx mr0">
                <img :src="require(`@/assets/images/cusotmer/${item.icon}`)"/>
              </div>
              <div class="r tx">
                <h3>{{ item.title }}</h3>
                <p class="ellipsis2">{{ item.detail }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_c_03">
      <h2>客户成功服务</h2>
      <ul class="serve_tab fx">
        <li :class="{active:memberServeTab===0}" @click="clickMemberServeTab(0)"><a href="javascript:;">陪伴式测评客户成功服务</a>
        </li>
        <li :class="{active:memberServeTab===1}" @click="clickMemberServeTab(1)"><a
            href="javascript:;">以价值为核心的软件客户成功服务</a></li>
      </ul>
      <div class="wrap_ser">
        <ul class="ser_01 mobile">
          <li class="mobile100 tx" v-for="item of memberSucc[memberServeTab]" :key="item.title">
            <h3>{{ item.title }}</h3>
            <p class="ellipsis3">{{ item.detail }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap_in wrap_c_04">
      <h2>客户支持服务</h2>
      <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
        <swiper-slide class="swiper-slide mobile" v-for="item of memberSupport" :key="item.title">
          <div class="fl tx">
            <h3>{{ item.title }}</h3>
            <label class="mfx" v-for="info of item.con" :key="info.info">
              <i></i>
              <span v-if="info.upportWeight">{{info.upportWeight}}</span>
              <p>{{ info.info }}</p>
            </label>
          </div>
          <div class="fr mobile100 mfx">
            <div class="item tx ml0" :style="'background: url('+require('@/assets/images/cusotmer/sli_bg1.png')+')'">
              <img src="@/assets/images/cusotmer/sli_icon1.png" alt="">
              <span>系统应用陪伴服务</span>
            </div>
            <div class="item tx" :style="'background: url('+require('@/assets/images/cusotmer/sli_bg2.png')+')'">
              <img src="@/assets/images/cusotmer/sli_icon2.png" alt="">
              <span>系统BUG快速解决服务</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-page" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-page',
          clickable: true,
          // type:'custom'
        },
      },
      serveTab: 0,
      serveData: [
        [
          {
            icon: 'zy_icon1.png',
            title: '蓝图规划',
            detail: '深入理解与洞察客户业务，分享企业实践基于客户需求快速梳理解决方案，建立成功基线',
          },
          {
            icon: 'zy_icon2.png',
            title: '业务实施',
            detail: '本地化现场服务+远程服务中心，快速实施分层分类对接，满足差异化客户需求',
          },
          {
            icon: 'zy_icon3.png',
            title: '能力建设',
            detail: '完善的客户培训体系，丰富的业务执行资源一对一远程个性化测评应用辅导',
          },
          {
            icon: 'zy_icon4.png',
            title: '业务实施',
            detail: '总结实施计划，确保客户以正确姿势开启测评之旅输出测评应用规范以及工具使用攻略',
          },
        ],
        [
          {
            icon: 'zy_icon01.png',
            title: '实施方法论',
            detail: '聚焦业务痛点，不是简单的实现将线下业务搬到线上引领成功实践，实现价值落地',
          },
          {
            icon: 'zy_icon02.png',
            title: '实施计划',
            detail: '首先通过基准实施，快速看到价值，然后进行迭代实施和价值实施',
          },
          {
            icon: 'zy_icon03.png',
            title: '实施价值',
            detail: '结合企业愿景，行业现状，发现客户面临的更多挑战，提供全方位的解决方案完善的培训体系，协助客户搭建内部运用体系',
          },
          {
            icon: 'zy_icon04.png',
            title: '实施目标',
            detail: '不只是一个结果，而是要真正达到预期目标落地业务流程规划与数据体系建设完成系统活跃度试运用，并定制提升计划',
          },
        ],
      ],
      memberServeTab: 0,
      memberSucc: [
        [
          {
            title: '业务优化',
            detail: '深入客户业务，诊断需求，优化测评应用，分享成功实践洞察客户更多潜在问题，帮助客户推进和解决核心问题',
          },
          {
            title: '能力建设',
            detail: '完善的培训及认证体系，提供学习资源，提升HR能力技术转移，持续能力建设，助力客户成为业务专家，能力专家',
          },
          {
            title: '增值服务',
            detail: '深入诊断客户问题，设计个性化解决方案提供轻量级咨询服务，如建模，评鉴中心等定制化服务',
          },
        ],
        [
          {
            title: '探索价值',
            detail: '持续关注客户商业变化，建立人才战略议题基于整体规划，进行目标差异分析',
          },
          {
            title: '提升价值',
            detail: '为客户设定量化目标，并持续跟踪，监控，改善和优化',
          },
          {
            title: '交付价值',
            detail: '基于用户变化的商业目标分析新特性，新目标提供成功实践，部署新价值',
          },
        ],
      ],
      memberSupport: [
        {
          title: '操作支持',
          con: [
            {
              upportWeight: '7*24',
              info: '小时系统应用陪伴服务'
            },
            {
              upportWeight: '7*24',
              info: '小时系统BUG快速解决服务'
            }
          ]
        },
        {
          title: '主动服务',
          con: [
            {info: '系统应用功能变监控'},
            {info: '系统重大BUG监控'},
            {info: '主动推送产品功能成功实践'},
            {info: '升级功能价值'},
          ]
        },
        {
          title: '增值服务',
          con: [
            {info: '以产品为基础的人工服务'},
            {info: '以标准服务产品为基础的人工额外服务'},
          ]
        },

      ]
    }
  },
    methods: {
      clickServeTab(i)
      {
        this.serveTab = i
      }
    ,
      clickMemberServeTab(i)
      {
        this.memberServeTab = i
      }
    }
  }
</script>

<style lang="scss">
.main {
  .img_wrap {
    width: 100%;
    position: relative;

    img {
      width: 100%
    }

    .fonts {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      color: #FFFFFF;

      h1 {
        font-size: 34px;
        margin-bottom: 40px;
        font-weight: bold;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  .wrap_in {
    > h2 {
      margin: 100px 0 60px;
      font-size: 28px;
      text-align: center;
      position: relative;
      padding-bottom: 20px;

      &:after {
        content: "";
        width: 18px;
        height: 4px;
        background: $themeColor;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
      }
    }

  }

  .wrap_c_01 {
    margin-bottom: 90px;

    ul {
      display: flex;
      justify-content: space-between;

      li {
        border: 1px solid #E3E3E3;
        width: 32%;
        margin-right: 2%;
        box-sizing: border-box;
        cursor: pointer;
        float: left;
        padding-bottom: 70px;

        &:last-child {
          margin-right: 0;
        }

        > a {
          .top {
            width: 100%;
            height: 235px;

            .circular {
              width: 135px;
              height: 135px;
              border-radius: 50%;
              background: #F5F5F5;
            }
          }

          span {
            font-size: 18px;
            color: #111111;
            font-weight: bold;
          }
        }

        &:hover {
          border-color: $themeColor;

          img {
            animation: changeScale .4s;
          }
        }
      }
    }
  }

  .serve_tab {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 70px;
    border: 1px solid $themeColor;

    li {
      width: 50%;
      height: 100%;
      font-size: 24px;

      a {
        display: block;
        padding: 16px;
        text-align: center;
        line-height: 1.5;
        color: $themeColor;
      }

      &.active {
        background: $themeColor;

        a {
          color: #FFFFFF;
        }
      }
    }
  }

  .wrap_c_02 {
    background: #EDEDED;
    overflow: hidden;

    .wrap_ser {
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
          padding: 60px 80px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .image {
            width: 118px;
            height: 118px;
            background: #FFFFFF;
            border-radius: 50%;
            margin-right: 40px;
            position: relative;
            flex-shrink: 0;

            img {
              width: 54px;
            }

            &:after {
              content: "";
              width: 125px;
              height: 125px;
              border: 1px solid #FFFFFF;
              position: absolute;
              border-radius: 50%;
            }
          }

          .r {
            h3 {
              font-size: 24px;
              color: #222222;
              margin-bottom: 30px;
            }

            p {
              font-size: 18px;
              color: #888888;
              overflow: hidden;
            }
          }

          &:hover {
            background: $themeColor;

            img {
              animation: changeScale .4s;
            }

            .r {
              h3, p {
                color: #FFFFFF
              }
            }
          }
        }
      }
    }
  }

  .wrap_c_03 {
    padding-bottom: 90px;
    background: #F8F9FB;

    .wrap_ser {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          width: 32%;
          margin-right: 2%;
          border-top: 5px solid $themeColor;
          padding: 55px;
          box-sizing: border-box;
          background: #FFFFFF;
          height: 258px;

          h3 {
            font-size: 24px;
            color: #222222;
            margin-bottom: 30px;
          }

          p {
            font-size: 18px;
            color: #888888;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            margin-top: -2px;
          }
        }
      }
    }
  }

  .wrap_c_04 {
    .swiper {
      max-width: 1320px;
      overflow: hidden;
    }

    .swiper-slide {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .fl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-size: 24px;
          margin-bottom: 55px;
        }

        label {
          display: flex;
          align-items: center;
          height: 45px;

          i {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $themeColor;
            margin-right: 5px;
          }

          span {
            color: $themeColor;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            color: #888888;
          }
        }
      }

      .fr {
        display: flex;

        .item {
          width: 250px;
          height: 349px;
          font-size: 22px;
          color: #FFFFFF;
          display: flex;
          align-content: center;
          justify-content: center;
          line-height: 2;
          flex-wrap: wrap;
          margin-left: 20px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: cover;

          span {
            margin-top: 30px;
            color: #FFFFFF;
          }

          &:hover {
            img {
              animation: changeScale .4s;
            }
          }
        }
      }
    }

    .swiper-page {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 90px 0;
      overflow: hidden;

      .swiper-pagination-bullet {
        margin: 0 3px;

        &.swiper-pagination-bullet-active {
          width: 26px !important;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
