<template>
  <div class="main">
    <div class="wrap_in footer">
      <div class="left">
        <div class="mobile">13518786721</div>
        <div class="date">周一至周日 9:00-21:30</div>
        <img src="@/assets/images/layout/Qrcode.png" alt="">
      </div>
      <div class="right">
        <div class="item">
          <h3>品牌及服务</h3>
          <ul>
            <li><a href="###">品牌全案</a></li>
            <li><a href="###">SEO</a></li>
            <li><a href="###">口碑</a></li>
            <li><a href="###">信息流广告</a></li>
          </ul>
        </div>
        <div class="item">
          <h3>新闻资讯</h3>
          <ul>
            <li><a href="###">公司动态</a></li>
            <li><a href="###">行业资讯</a></li>
          </ul>
        </div>
        <div class="item">
          <h3>关于我们</h3>
          <ul>
            <li><a href="/about">公司简介</a></li>
            <li><a href="/about/history">发展历程</a></li>
            <li><a href="/about/contact">联系鹰能</a></li>
            <li><a href="/about/join">加入鹰能</a></li>
          </ul>
        </div>
        <div class="item">
          <h3>联系我们</h3>
          <ul>
            <li>商务经理：13518786721</li>
            <li>邮箱：toucan.net.com</li>
            <li>地址：云南省昆明市环城东路455号</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrap_in copyright-icp">
        <p class="p1">©2020-2021 云南鹰能科技有限公司</p>
        <p>copyright 云南鹰能科技有限公司 版权所有</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
  .main{
    background: #3C8CFF;
    .footer{
      display: flex;
      justify-content: space-between;
      padding-top: 0.8rem;
      padding-bottom: 0.2rem;
      .left{
        width: 130px;
        font-size: 17px;
        text-align: center;
        color: #FFFFFF;
        .mobile{
          font-weight: bold;
        }
        .date{
          font-size: 12px;
          margin: 10px 0 15px;
        }
        img{
          width: 130px;
          height: 130px;
          background: #eeeeee;
          margin-bottom: 15px;
        }
      }
      .right{
        display: flex;
        justify-content: space-between;
        .item{
          margin-right: 8vw;
          &:last-child{
            margin-right: 0;
          }
          h3{
            color: #FFFFFF;
            font-size: 16px;
          }
          ul{
            margin-top: 30px;
            font-size: 14px;
            li{
              color: #FFFFFF;
              margin-bottom: 20px;
              a{
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
    .copyright-icp{
      color: #FFFFFF;
      font-size: 14px;
      text-align: center;
      padding-bottom: .3rem;
      .p1{
        padding-top: .3rem;
        margin-bottom: 5px;
        border-top: 1px solid rgba(255,255,255,0.5);
      }
    }
  }
</style>
