<template>
  <div>
    <el-dialog
        :close-on-click-modal="false"
        title="用户登录"
        style="top: 20px; height: 1000px"
        width="33%"
        :visible.sync="visible"
    >
      <el-form
          id="formLogin"
          :rules="rules"
          class="user-layout-login"
          ref="form"
          :model="form"
      >
        <el-tabs
            v-model="customActiveKey"
            :style="{ textAlign: 'center', borderBottom: 'unset' }"
            @tab-click="handleTabClick"
        >
          <el-tab-pane name="tab1" label="账号密码登录">
            <el-alert
                v-if="isLoginError"
                type="error"
                showIcon
                style="margin-bottom: 24px"
                :title="errorMsg"
            />
            <el-form-item prop="username">
              <el-input size="large" placeholder="账户" v-model="form.username">
                <i slot="prefix" class="el-icon-user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  size="large"
                  placeholder="密码"
                  :type="showPass?'text':'password'"
                  v-model="form.password">
                <i slot="prefix" class="el-icon-lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane name="tab2" label="手机扫码登录" disabled>
          </el-tab-pane>
        </el-tabs>
        <el-form-item>
          <el-checkbox v-model="form.rememberMe">自动登录
          </el-checkbox>
          <!--          <router-link-->
          <!--              :to="{ name: 'recover', params: { user: 'aaa' } }"-->
          <!--              class="forge-password"-->
          <!--              style="float: right"-->
          <!--          >忘记密码-->
          <!--          </router-link          >-->
        </el-form-item>
        <el-form-item>
          <div>
            <verify
                @success="capctchaCheckSucc"
                :captchaType="'blockPuzzle'"
                :imgSize="{ width: '350px', height: '155px' }"
                ref="verify"
            ></verify>
          </div>
        </el-form-item>
        <el-form-item style="margin-top: 24px">
          <el-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="loading"
              @click="loginForm"
          >确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {mapActions} from 'vuex'
import JSEncrypt from 'jsencrypt/bin/jsencrypt'
import {getPubKey, login} from '@/api/login'
import Verify from "@/components/verifition/Verify";

export default {
  components: {
    Verify
  },
  data() {
    return {
      visible: false,
      showPass: false,
      capctchaCheck: false,
      pubKey: '',
      verify: '',
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      errorTimes: 0,
      errorMsg: '',
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      confirmLoading: false,
      // form: this.$form.createForm(this),
      loading: false,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {},
  created() {
    getPubKey().then((res) => {
      if (res.code == 0) {
        this.pubKey = res.key
      }
    })
  },
  methods: {
    show() {
      this.isLoginError = false
      this.visible = true
    },
    ...mapActions(['Login', 'Logout']),

    handleTabClick(key) {
      // this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.loginForm()
    },
    capctchaCheckSucc(data) {
      this.capctchaCheck = true
      this.verify = data.captchaVerification
    },
    loginForm() {
      setTimeout(() => {
        if (!this.capctchaCheck && this.errorTimes >= 1) {
          this.$refs.verify.show()
          this.isLoginError = true
          this.errorMsg = '请先进行图片验证'
          return false
        }

        this.loading = true
        this.$refs.form.validate((valid) => {
          if (valid) {
            const loginParams = {...this.form}
            if (this.pubKey) {
              const encryptor = new JSEncrypt()
              encryptor.setPublicKey(this.pubKey)
              loginParams.password = encryptor.encrypt(loginParams.password)
            }
            const captchaVO = {captchaVerification: this.verify}
            loginParams.captchaVO = captchaVO

            this.Login(loginParams)
                .then((res) => this.loginSuccess(res))
                .catch((err) => this.requestFailed(err))
                .finally(() => {
                  this.loading = false
                  this.capctchaCheck = false
                })
          } else {
            this.loading = false
          }
        })
      }, 500);

    },
    loginSuccess(res) {
      if (res.code === 0) {
        console.log('登录成功')
        this.visible = false
        setTimeout(() => {
          window.location.reload()
        }, 16)
        this.isLoginError = false
      } else {
        this.$refs.verify.refresh()
        this.requestFailed(res)
      }
    }
    ,
    requestFailed(err) {
      this.errorTimes += 1
      this.isLoginError = true
      this.errorMsg =
          ((err.response || {}).data || {}).msg || err.msg || '请求出现错误，请稍后再试'
      this.$refs.verify.show()
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
