import { axios } from '@/utils/request'

const api = {
  sysApp: '/system/sysApp'
}

export function getSysAppList (parameter) {
  return axios({
    url: api.sysApp + '/list',
    method: 'get',
    params: parameter
  })
}


export function getSysAppLatests (parameter) {
  return axios({
    url: api.sysApp + '/get/latests',
    method: 'get',
    params: parameter
  })
}


export function saveSysApp (parameter) {
  return axios({
    url: api.sysApp + (parameter.get('id') ? '/update' : '/save'),
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function delSysApp (parameter) {
  return axios({
    url: api.sysApp + '/remove',
    method: 'post',
    params: parameter
  })
}

export const sysAppExport = api.sysApp + '/export'
