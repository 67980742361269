<template>
  <div>
    <div class="main">
      <div class="wrap_in center-wrap clearfix indexbody">
        <router-link class="go-admin" to="###" @click.native ="jumpTo('https://www.ynab.com.cn/')"><img src="@/assets/images/index/hot.png" alt="头条"/>
          <p>头条</p>云南鹰能智慧安保系统快速入口
        </router-link>
        <div class="banner-wrap">
          <div class="banner">
            <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
              <swiper-slide class="swiper-slide"><img src="@/assets/images/index/banner1.png" alt=""></swiper-slide>
              <swiper-slide class="swiper-slide"><img src="@/assets/images/index/banner1.png" alt=""></swiper-slide>
              <swiper-slide class="swiper-slide"><img src="@/assets/images/index/banner1.png" alt=""></swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="product-wrap">
            <div class="box">
              <div class="title">预约产品展示</div>
              <ul>
                <li v-for="(item,i) of advanceIcon" :key="item.title" :class="'li'+i">
                  <a :href="item.url" class="img-bg" :style="'background:'+item.color">
                    <img :src="imageUrl('advanceIcon/'+item.icon)" :alt="item.title">
                  </a>
                  <span>{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="wrap_index_02">
          <div class="w720 fl">
            <div class="item-box fl news">
              <div class="title-wrap">
                <h3>{{ news.title }}</h3>
                <router-link to="/news">更多</router-link>
              </div>
              <div class="news-content">
                <router-link :to="{path:'/article',query:{id:news.articleArr[0].id}}" class="h"
                             :title="news.articleArr[0].title">
                  {{ news.articleArr[0].title }}
                </router-link>
                <router-link :to="'/article?id='+news.articleArr[0].id" class="p">{{
                    news.articleArr[0].detailsTitle
                  }}
                </router-link>

                <div class="srt">
                  <ul class="common">
                    <li v-for="article of news.articleArr.slice(1,6)" :key="article.id">
                      <a :href="'/article?id='+article.id" :title="article.title">{{ article.title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="item-box fr mt">
              <div class="title-wrap">
                <h3>{{ medias.title }}</h3>
                <router-link to="/news">更多</router-link>
              </div>
              <div class="news-content">
                <router-link :to="{path:'/article',query:{id:medias.articleArr[0].id}}" class="h"
                             :title="medias.articleArr[0].title">
                  {{ medias.articleArr[0].title }}
                </router-link>
                <router-link :to="{path:'/article',query:{id:medias.articleArr[0].id}}" class="p">
                  {{ medias.articleArr[0].detailsTitle }}
                </router-link>

                <div class="srt">
                  <ul class="common">
                    <li v-for="article of medias.articleArr.slice(1,6)" :key="article.id">
                      <a :href="'/article?id='+article.id" :title="article.title">{{ article.title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="w465 fr">
            <div class="news item-box">
              <div class="title-wrap">
                <h3>{{ notices.title }}</h3>
                <router-link to="/news">更多</router-link>
              </div>
              <div class="news-content">
                <!--                <router-link :to="'/article?id='+notices.articleArr[0].id" class="h">{{-->
                <!--                    notices.articleArr[0].title-->
                <!--                  }}-->
                <!--                </router-link>-->
                <!--                <ul class="common notice">-->
                <!--                  <li v-for="portalArticle of notices.articleArr.slice(1,4)" :key="portalArticle.id">-->
                <!--                    <a :href="'/article?id='+article.id" :title="portalArticle.title">{{ portalArticle.title }}</a>-->
                <!--                  </li>-->
                <!--                </ul>-->
              </div>
            </div>
            <div class="activity">
              <div class="title-wrap">
                <h3>活动专题</h3>
                <router-link to="###">更多</router-link>
              </div>
              <div class="content">
                <div class="swiper-button-prev slide-btn"></div>
                <div class="center">
                  <swiper ref="mySwiper" :options="swiperOptionsActivity">
                    <swiper-slide v-for="item of [0,1,2,3]" :key="item">
                      <span :style="{background: `url(${imageUrl('right_xia.png')})`}">鹰能安保培训现场{{ item }}</span>
                    </swiper-slide>
                  </swiper>
                </div>
                <div class="swiper-button-next slide-btn"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="shop_wrap">
          <h2>推荐产品</h2>
          <div class="content_wrap">
            <ul class="title_wrap">
              <li @click="changeShopItem(index)" :class="{active:shopItem===index}"
                  v-for="(category,index) in shopData.shopCategorys" :key="index">
                <a href="javascript:;">{{ category }}</a>
              </li>
            </ul>
            <div class="info">
              <div class="item" v-for="item of shopData.goods[shopItem]" :key="item.image">
                <div class="img_box">
                  <img :src="imageUrl('goods/'+item.image)">
                </div>
                <div class="con">
                  <h3 class="ellipsis">{{ item.title }}</h3>
                  <p class="ellipsis3">{{ item.info }}</p>
                  <s>￥7599</s>
                  <i>低至<strong>￥6399</strong></i>
                  <span>节省了<font color="red">￥1200（16%）</font></span>
                  <font color="red">日常销售价6999，抢购价6399</font>
                  <a href="javascript:;">立即抢购</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'


import {getPortalArticleCateList} from "@/api/portal/portalArticleCate";
import {getPortalArticleList} from "@/api/portal/portalArticle";

export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOptionsActivity: {
        autoplay: true,
        speed: 1000,
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        }
      },
      advanceIcon: [
        {title: '调度指挥', icon: 'ddzh.png', color: '#0FBABA', url: '/product/dispatching'},
        {title: '标书制作', icon: 'bszz.png', color: '#597EF7', url: '/solution/bid'},
        {title: '项目管理', icon: 'xmgl.png', color: '#32A2FF', url: '/solution/project'},
        {title: '人事管理', icon: 'rsgl.png', color: '#FA8C16', url: '/product/finance'},
        {title: '风险管理', icon: 'fxgl.png', color: '#1890FF', url: '/product/risk'},
        {title: '安保商城', icon: 'absc.png', color: '#8DC70F', url: '/product/train'}
      ],
      news: {
        title: '',
        articleArr: [
          {
            title: '',
            detailsTitle: ''
          }
        ]
      },
      medias: {
        title: '',
        articleArr: [
          {
            title: '',
            detailsTitle: ''
          }
        ]
      },
      notices: {
        title: '',
        portalArticle: [
          {title: ''}
        ]
      },
      shopItem: 0,
      shopData: {
        shopCategorys: ['热销装备', '单警装备', '防护装备', '对讲机', '电击器'],
        goods: [
          [{
            image: '1_01.png',
            title: 'MD-3003B1手持式金属检测仪',
            info: 'MD-3003B1型手持式金属探测器，主要用于需要探测金属的场所，特别适用于对探测灵敏度要求极高的公共场所或工厂使用，机场、公、检、法、司、体育场馆、会展场馆、酒吧、车站码头等行业的安全检查；'
          }, {
            image: '1_02.png',
            title: 'ZJSC-P10防水通过式金属探测安检门',
            info: '中警思创ZJSC-P10防水通过式金属探测安检门具有超高灵敏度、极强的抗扰能力和抗震能力，广泛应用于监狱、法院、学校、医院、电子企业、港口、客运站、展览会馆、会议中心、重大庆典、演唱会等重要场所。'
          }, {
            image: '1_03.png',
            title: '意大利进口 PD140E手持金属探测器',
            info: 'PD140E 符合人体工程学设计的具有先进的检测能力和操作员信号提示特点的手持式金属探测器。这个新设备的主要特点是探测金属物的灵敏度高，完全符合最新的安全标准并可以探测到更多奇特的金属物。'
          }, {
            image: '1_04.png',
            title: '美国 Rapiscan Metor28手持金属检测仪',
            info: '麦特Metor28进口安检仪是麦特瑞克生产的最杰出的手持式探测仪，小巧灵敏度高，检测效果好，便携，是难得的检测产品，物品的检测。这是一种与安检门相互配合使用的手持探测仪'
          }, {
            image: '1_05.png',
            title: '美国盖瑞特SuperScanner 手持式探测器',
            info: '作为拥有40年生产历史的美国盖瑞特公司的产品，盖瑞特SuperScanner手持式探测器已经在世界的各个领域中，发挥了近20年的作用。'
          }],
          [{
            image: '2_01.png',
            title: '执法记录仪DSJ-LJ',
            info: '设备主体外观颜色为黑色，材质为工程塑料，存储介质采用内置设计。电池非内置固定，采用可拆卸更换式，便于随时更换备用电池，保证续航使用时间'
          }, {
            image: '2_02.png',
            title: '防刺服',
            info: '采用超高强超高密度聚乙烯短切纱和芳纶短切纱混纺制造的防刺材料制造的软质防刺服，也称为防刺背心或防刺衣，能有效的阻挡刀具等冷兵器的刺、割、砍的攻击，全面保护人体躯干和内脏免遭伤害。该服装备所选用的材料具有断裂强度高、弹性模量大等优异特性。'
          }, {
            image: '2_03.png',
            title: 'DSJ-5G智能执法记录仪',
            info: '亮见5G智能执法记录综合管理系统综合运用了智能终端、视音频通讯、云共享等先进技术，通过软硬结合的方式全面解决执法全过程音视频记录管理中的难题。'
          }, {
            image: '2_04.png',
            title: '双目单筒夜视仪',
            info: '该款夜视仪体积小，重量轻，配合头盔使用。采用高级图像增强器和先进的光学系统，具有高分辨率等特点。更换物镜可以改变放大倍率和观察距离，备有1倍和4倍镜头可供选择。该产品可以与安装在枪上的激光红外照射器配套使用，对150米以内的目标进行瞄准射击。该产品也可以作手持式微光望远镜使用，并采用俄罗斯超二代图像增强器，具有防强光和防眩目功能，内置红外辅助照明光源，可用于观察，监视，，边防侦察，检查缉私等。'
          }, {
            image: '2_05.png',
            title: '钢狼牙 LYG-AH01',
            info: '型号：LYG-AH01长度：45、50、55、60cm;手柄：橡胶;材质：钢;颜色：黑色;直径：28mm质量：490g(50cm)'
          }],
          [{
            image: '3_01.png',
            title: '供应振弘 FDY3RC6F-ZH02 防弹衣',
            info: '防弹衣能吸收和耗散弹头、破片动能，阻止穿透，有效保护人体受防护部位，用于防护弹头或弹片对人体的伤害。'
          }, {
            image: '2_02.png',
            title: '防刺服',
            info: '采用超高强超高密度聚乙烯短切纱和芳纶短切纱混纺制造的防刺材料制造的软质防刺服，也称为防刺背心或防刺衣，能有效的阻挡刀具等冷兵器的刺、割、砍的攻击，全面保护人体躯干和内脏免遭伤害。该服装备所选用的材料具有断裂强度高、弹性模量大等优异特性。'
          }, {
            image: '2_03.png',
            title: 'DSJ-5G智能执法记录仪',
            info: '亮见5G智能执法记录综合管理系统综合运用了智能终端、视音频通讯、云共享等先进技术，通过软硬结合的方式全面解决执法全过程音视频记录管理中的难题。'
          }, {
            image: '2_04.png',
            title: '双目单筒夜视仪',
            info: '该款夜视仪体积小，重量轻，配合头盔使用。采用高级图像增强器和先进的光学系统，具有高分辨率等特点。更换物镜可以改变放大倍率和观察距离，备有1倍和4倍镜头可供选择。该产品可以与安装在枪上的激光红外照射器配套使用，对150米以内的目标进行瞄准射击。该产品也可以作手持式微光望远镜使用，并采用俄罗斯超二代图像增强器，具有防强光和防眩目功能，内置红外辅助照明光源，可用于观察，监视，，边防侦察，检查缉私等。'
          }, {
            image: '2_05.png',
            title: '钢狼牙 LYG-AH01',
            info: '型号：LYG-AH01长度：45、50、55、60cm;手柄：橡胶;材质：钢;颜色：黑色;直径：28mm质量：490g(50cm)'
          }],
          [{
            image: '3_01.png',
            title: '供应振弘 FDY3RC6F-ZH02 防弹衣',
            info: '防弹衣能吸收和耗散弹头、破片动能，阻止穿透，有效保护人体受防护部位，用于防护弹头或弹片对人体的伤害。'
          }, {
            image: '2_02.png',
            title: '防刺服',
            info: '采用超高强超高密度聚乙烯短切纱和芳纶短切纱混纺制造的防刺材料制造的软质防刺服，也称为防刺背心或防刺衣，能有效的阻挡刀具等冷兵器的刺、割、砍的攻击，全面保护人体躯干和内脏免遭伤害。该服装备所选用的材料具有断裂强度高、弹性模量大等优异特性。'
          }, {
            image: '2_03.png',
            title: 'DSJ-5G智能执法记录仪',
            info: '亮见5G智能执法记录综合管理系统综合运用了智能终端、视音频通讯、云共享等先进技术，通过软硬结合的方式全面解决执法全过程音视频记录管理中的难题。'
          }, {
            image: '2_04.png',
            title: '双目单筒夜视仪',
            info: '该款夜视仪体积小，重量轻，配合头盔使用。采用高级图像增强器和先进的光学系统，具有高分辨率等特点。更换物镜可以改变放大倍率和观察距离，备有1倍和4倍镜头可供选择。该产品可以与安装在枪上的激光红外照射器配套使用，对150米以内的目标进行瞄准射击。该产品也可以作手持式微光望远镜使用，并采用俄罗斯超二代图像增强器，具有防强光和防眩目功能，内置红外辅助照明光源，可用于观察，监视，，边防侦察，检查缉私等。'
          }, {
            image: '2_05.png',
            title: '钢狼牙 LYG-AH01',
            info: '型号：LYG-AH01长度：45、50、55、60cm;手柄：橡胶;材质：钢;颜色：黑色;直径：28mm质量：490g(50cm)'
          }],
          [{
            image: '3_01.png',
            title: '供应振弘 FDY3RC6F-ZH02 防弹衣',
            info: '防弹衣能吸收和耗散弹头、破片动能，阻止穿透，有效保护人体受防护部位，用于防护弹头或弹片对人体的伤害。'
          }, {
            image: '2_02.png',
            title: '防刺服',
            info: '采用超高强超高密度聚乙烯短切纱和芳纶短切纱混纺制造的防刺材料制造的软质防刺服，也称为防刺背心或防刺衣，能有效的阻挡刀具等冷兵器的刺、割、砍的攻击，全面保护人体躯干和内脏免遭伤害。该服装备所选用的材料具有断裂强度高、弹性模量大等优异特性。'
          }, {
            image: '2_03.png',
            title: 'DSJ-5G智能执法记录仪',
            info: '亮见5G智能执法记录综合管理系统综合运用了智能终端、视音频通讯、云共享等先进技术，通过软硬结合的方式全面解决执法全过程音视频记录管理中的难题。'
          }, {
            image: '2_04.png',
            title: '双目单筒夜视仪',
            info: '该款夜视仪体积小，重量轻，配合头盔使用。采用高级图像增强器和先进的光学系统，具有高分辨率等特点。更换物镜可以改变放大倍率和观察距离，备有1倍和4倍镜头可供选择。该产品可以与安装在枪上的激光红外照射器配套使用，对150米以内的目标进行瞄准射击。该产品也可以作手持式微光望远镜使用，并采用俄罗斯超二代图像增强器，具有防强光和防眩目功能，内置红外辅助照明光源，可用于观察，监视，，边防侦察，检查缉私等。'
          }, {
            image: '2_05.png',
            title: '钢狼牙 LYG-AH01',
            info: '型号：LYG-AH01长度：45、50、55、60cm;手柄：橡胶;材质：钢;颜色：黑色;直径：28mm质量：490g(50cm)'
          }],
        ],
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    imageUrl() {
      return url => require('@/assets/images/index/' + url)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    /** 跳转到子网地址 */
    jumpTo(url) {
      window.open(url,"_blank");
    },
    init() {
      this.getGroupList()
    },

    /**
     * 获取分组列表
     * @returns {Promise<void>}
     */
    async getGroupList() {
      let portalArticleCateList = await getPortalArticleCateList();
      console.log(portalArticleCateList)
      portalArticleCateList = portalArticleCateList.rows
      portalArticleCateList.map(async (portalArticleCate, i) => {
        let portalArticle = await getPortalArticleList({cateId: portalArticleCate.id})
        portalArticleCate.portalArticle = portalArticle.rows
        switch (i) {
          case 0:
            this.news.title = portalArticleCate.title
            this.news.articleArr = portalArticleCate.portalArticle
            break;
          case 1:
            this.medias.title = portalArticleCate.title
            this.medias.articleArr = portalArticleCate.portalArticle
            break;
          case 2:
            this.notices.title = portalArticleCate.title
            this.notices.articleArr = portalArticleCate.portalArticle
        }
        return portalArticleCate;
      })
      return portalArticleCateList;
    },
    changeShopItem(index) {
      this.shopItem = index
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
