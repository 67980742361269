import axios from 'axios'
import moment from 'moment'
import store from '@/store'
import storage from 'store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const baseURL = process.env.VUE_APP_API_BASE_URL
// 创建 axios 实例
const request = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 6000 // 请求超时时间
})
export const pureAxios = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      // notification.error({
      //   message: 'Forbidden',
      //   description: data.message
      // })
      console.log('Forbidden => ' + data.message)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // notification.error({
      //   message: 'Unauthorized',
      //   description: 'Authorization verification failed'
      // })
      console.log('Unauthorized => Authorization verification failed')
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

pureAxios.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}

export function appendToFromData (form_data, obj) {
  var data = []
  if (obj instanceof File) {
    data.push({ key: '', value: obj })
  } else if (obj instanceof Array) {
    for (var j = 0, len = obj.length; j < len; j++) {
      var arr = appendToFromData(null, obj[j])
      for (var k = 0, l = arr.length; k < l; k++) {
        var key = form_data ? j + arr[k].key : '[' + j + ']' + arr[k].key
        data.push({ key: key, value: arr[k].value })
      }
    }
  } else if (typeof obj === 'object') {
    if (moment.isMoment(obj)) {
      obj = moment(obj).format('YYYY-MM-DD HH:mm:ss')
      data.push({ key: '', value: obj })
    } else {
      for (var j in obj) {
        var arr = appendToFromData(null, obj[j])
        for (var k = 0, l = arr.length; k < l; k++) {
          var key = form_data ? j + arr[k].key : '.' + j + ( (arr[k].key && arr[k].key.indexOf('[')==0) ? '' : (arr[k].key ? '.' : '')) + arr[k].key
          data.push({ key: key, value: arr[k].value })
        }
      }
    }
  } else {
    data.push({ key: '', value: obj })
  }
  if (form_data) {
    // 封装
    for (var i = 0, len = data.length; i < len; i++) {
      if (data[i].value !== undefined) {
        form_data.set(data[i].key, data[i].value)
      }
    }
    console.log('FormData====>')
    form_data.forEach((value, key) => {
      console.log('key %s: value %s', key, value)
      console.log('======>FormData')
    })
  } else {
    return data
  }
}
