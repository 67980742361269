<template>
<div class="wrap_ab_03">
  <div class="ab_03_t">
    <span>发展历程</span>
  </div>
  <div class="ab_03_history_logo_wrap">
    <img src="" alt="">
    <span>2021年</span>
    <p>创新发布新一代一体化安保 SaaS管理平台</p>
  </div>
  <div class="ab_03_history">
    <i></i>
    <ul>
      <li v-for="(item,index) in historyData" :key="index">
        <div class="left_right r" :class="index%2?'date':'info'">
          {{index%2?item.date:item.title}}
        </div>
        <div class="history_c">
          <i></i>
        </div>
        <div class="left_right" :class="index%2?'info':'date'">{{index%2?item.title:item.date}}</div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>

// import 'lib-flexible'
export default {
  name: "History",
  data () {
    return {
      historyData: [
        {
          date: '2021年9月',
          title: '完成保安调度指挥管理平台研发，鹰能安保3.0版本上线'
        },
        {
          date: '2021年6月',
          title: '人力资源及薪酬计算功能上线'
        },
        {
          date: '2021年4月',
          title: '鹰能安保2.0上线，市场销售、项目管理全面单轨'
        },
        {
          date: '2021年初',
          title: '鹰能安保1.0上线，系统开发大规模测试'
        },
        {
          date: '2021年',
          title: '全面进军安保行业，为安保公司提供全面的一站式信息系统解决方案'
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap_ab_03 {
  padding-top: 88px;

  .ab_03_t {
    text-align: center;

    span {
      position: relative;
      padding-bottom: 13px;
      font-size: 44px;
      &:after {
        content: "";
        width: 18px;
        height: 4px;
        display: block;
        overflow: hidden;
        background-color: $themeColor;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
      }
    }
  }

  .ab_03_history_logo_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 39px;

    img, span {
      width: 218px;
    }

    span {
      display: block;
      overflow: hidden;
      width: 218px;
      height: 54px;
      font-size: 36px;
      color: #FFFFFF;
      background-color: $themeColor;
      text-align: center;
      z-index: 2;
    }

    p {
      width: 100%;
      height: 44px;
      overflow: hidden;
      text-align: center;
      font-size: 14px;
      color: #666;
      line-height: 44px;
      margin-top: 5px;
    }
  }

  .ab_03_history {
    margin-top: 0.18rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > i {
      width: 0;
      height: 0;
      border: 0.15rem solid #F4F5FB;
      border-top-color: $themeColor;
    }

    ul {
      width: 100%;

      li {
        height: 118px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        div {
          height: 100%;
          line-height: 118px
        }

        .left_right {
          width: 46%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .history_c {
          width: 8%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          i {
            width: 0.15rem;
            height: 0.15rem;
            background: $themeColor;
            border-radius: 50%;
          }

          &:before {
            content: "";
            width: 1px;
            height: 0.7rem;
            background: #D8D8D8;
          }

          &:after {
            content: "";
            width: 1px;
            height: 0.7rem;
            background: #D8D8D8;
          }
        }

        .r {
          justify-content: flex-end
        }

        .date {
          font-size: 36px;
          color: $themeColor;
        }

        .info {
          overflow: hidden;
          font-size: 14px;
          line-height: 32px;
          color: #666;
        }
      }
    }
  }
}
</style>
