<template>
  <div class="main">
    <div class="newComm_banner">
      <img src="@/assets/images/news/banner.jpg" alt="">
    </div>
    <div class="wrap_in clearfix">
      <div class="cter_l">
        <div class="cter_t">
          <span>全部</span>
        </div>
        <div class="cter_l_c">
          <ul v-for="newItem of news.rows" :key="newItem.id">
            <li>
              <a :href="'/article?id='+newItem.id">
                <em class="em1">{{newItem.image}}
                  <img :src="newItem.image || require(`@/assets/images/news/news.png`)">
                </em>
                <em class="em2">
                  <span class="sp1">{{ newItem.title }}？</span>
                  <span class="sp2">{{ newItem.detailsTitle }}</span>
                  <span class="sp3"><i class="i1">{{ newItem.clickNum }}</i>
                    <i class="i2">{{newItem.cateTitle}}</i>
                  </span>
                </em>
              </a>
            </li>
          </ul>
        </div>
        <nav class="navigation">
          <ul class="wrap_in">
            <li @click="setPage('pre')">
              <a href="javascript:;" aria-label="Previous">
                <span aria-hidden="true">上一页</span>
              </a>
            </li>
            <li :class="{active:pageNum===page}" @click="setPage(page)" v-for="page in pageArr" :key="page">
              <a href="javascript:;">{{ page }}</a>
            </li>
            <li @click="setPage('next')">
              <a href="javascript:;" aria-label="Next">
                <span aria-hidden="true">下一页</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
<!--      <div class="cter_r">
        <Search></Search>
      </div>-->
    </div>
  </div>
</template>

<script>

// import Search from "../../components/Search";
import {getPortalArticleList} from "@/api/portal/portalArticle";

export default {
  name: "index",
  components: {
    // Search
  },
  data () {
    return {
      pageSize: 5,
      pageNum: 1,
      news: '',
      pageArr: [1],
      pageMax: 1
    }
  },
  mounted () {
    this.getNewsList()
  },
  methods: {
    getNewsList () {
      const {pageSize, pageNum} = this
      getPortalArticleList({pageSize, pageNum}).then(res => {
        this.news = res
        const page = Math.ceil(res.total / pageSize)
        this.pageMax = page
        this.pageArr = Array.from({length: page}).map((item, index) => index + 1)
      })
    },
    setPage (pageNum) {
      if (pageNum === 'pre') this.pageNum = this.pageNum - 1
      else if (pageNum === 'next') this.pageNum = this.pageNum + 1
      else this.pageNum = pageNum

      if (this.pageNum < 1) this.pageNum = 1
      if (this.pageNum > this.pageMax) this.pageNum = this.pageMax

      this.getNewsList()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background: #F4F5FB;

}

.newComm_banner {
  width: 100%;
  background: url("../../../src/assets/images/news/banner.jpg") no-repeat;
  background-size: cover;

  img {
    width: 100%;
  }
}

.wrap_in {
  margin-top: .4rem;

  .cter_l {
    width: 100%;
    float: left;
    padding: 0 0.38rem;
    border-radius: 0.04rem;
    background: #fff;

    .cter_t {
      width: 100%;
      font-size: 20px;
      color: $themeColor;
      padding: 8px 0;
      border-bottom: 1px solid #E5E5E5;

      span {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          left: 0;
          bottom: -5px;
          width: 100%;
          border-bottom: 3px solid $themeColor;
        }
      }
    }

    .cter_l_c {
      margin-top: 0.4rem;
      overflow: hidden;

      ul {
        li {
          width: 100%;
          overflow: hidden;
          border-bottom: 1px dashed #e8e8e8;
          padding-bottom: 0.39rem;
          margin-bottom: 0.31rem;

          .em1 {
            width: 30%;
            float: left;
            overflow: hidden;

            img {
              float: left;
              width: 100%;
            }
          }

          .em2 {
            width: 67%;
            float: right;
            overflow: hidden;

            .sp1 {
              display: block;
              font-size: 18px;
              color: #444;
              line-height: 1.5;
            }

            .sp2 {
              display: block;
              font-size: 14px;
              color: #888;
              line-height: 1.5;
              margin-top: 0.15rem;
            }

            .sp3 {
              display: block;
              margin-top: 0.12rem;
              line-height: 1.5;
              font-size: 16px;
              color: #888;

              .i1 {
                display: inline-block;
                background: url(../../assets/images/news/cter_07.jpg) left center no-repeat;
                background-size: 0.23rem;
                padding-left: 0.32rem;
              }

              .i2 {
                display: inline-block;
                background: url(../../assets/images/news/cter_06.jpg) left center no-repeat;
                background-size: 0.17rem;
                padding-left: 0.26rem;
                margin-left: 0.4rem;
              }
            }
          }
        }
      }
    }

    .Page {
      display: flex;
      justify-content: center;
    }
  }

  .cter_r {
    float: right;
    width: 28%;
    border-radius: 0.04rem;
    background: #fff;

  }
}
</style>
