import Vue from 'vue'
import VueRouter from 'vue-router'

import route1 from "./Router";

Vue.use(VueRouter)

/*const route2 = [
	{
		path: '/',
		name: 'Index',
		component: Index
	},
	{
		path: '/news',
		name: 'News',
		component: () => import('@/views/News')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('@/views/About')
	},
	{
		path: '/history',
		name: 'History',
		component: () => import('@/views/About/History')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('@/views/About/Contact')
	},
	{
		path: '/article',
		name: 'Article',
		component: () => import('@/views/Article')
	},
	{
		path: '/customer',
		name: 'Customer',
		component: () => import('@/views/Customer')
	},
	{
		path: '/rcpd',
		name: 'Rcpd',
		component: () => import('@/views/Solution/Rcpd')
	},
	{
		path: '/rck',
		name: 'Rck',
		component: () => import('@/views/Solution/Rck')
	},
	{
		path: '/dispatching',
		name: 'Dispatching',
		component: () => import('@/views/Product/Dispatching')
	}
]*/


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	// route1
})
router.addRoutes(route1)

export default router
