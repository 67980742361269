<template>
  <div class="main">
    <div class="banner-wrap">
      <img src="@/assets/images/about/ab_01.jpg" alt="">
    </div>

    <div class="wrap_in">
      <Timeline></Timeline>
    </div>
  </div>
</template>

<script>

import Timeline from "@/components/Timeline";
export default {
  name: "index",
  components:{
    Timeline
  },
  data () {
    return {
      historyData: [
        {
          date: '2021年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP'
        },
        {
          date: '2020年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP '
        },
        {
          date: '2019年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打实sdad啥地方撒大声地asdasda'
        },
        {
          date: '2018年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP 地方撒大声地asdasda'
        },
        {
          date: '2017年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打地asdasda'
        },
        {
          date: '2016',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storad啥地方撒大声地asdasda'
        },
        {
          date: '2015年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打实sdad啥地声地asdasda'
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
$themeColor: #2079bc;

.main {
  .banner-wrap,img{
    width: 100%;
  }
  .wrap_ab_03{
    padding-top: 1.5rem;
  }
  .wrap_in{
    background: #F4F5FB;
  }
}

</style>
