<template>
  <div class="main">
    <div class="banner-wrap">
      <img src="@/assets/images/about/ab_01.jpg" alt="">
    </div>
    <div class="ab_01_t">
      <span>鹰能科技公司</span>
    </div>
    <div class="wrap_in wrap_ab_01">
      <div class="wrap_cont">
        <h2>一、鹰能是谁</h2>
        <p><br></p>
        <p><span style="font-size: 16px;">鹰能是一家安保科技公司，安保行业垂直型SaaS服务领导者， 成为安保行业的Salesforce。</span>
        </p>
        <p>&nbsp;</p>
        <h2>二、鹰能能做什么？</h2>
        <p><br></p>
        <p><span
            style="font-size: 16px;">鹰能以安保管理技术为内核，以核心项目、人力为基础，将安保各业务模块管理的各个模块紧密集成在一起，形成安保行业垂直型SaaS服务领导者，包括：</span>
        </p>
        <p><span style="font-size: 16px;">l 核心功能：调度指挥管理、项目管理、标书制作管理、风险管理、人事管理等安保解决方案。</span></p>
        <p><span style="font-size: 16px;">l 调度指挥：一张电子图上可以完成电话、对讲、广播、视频、报警和远程控制等操作。</span></p>
        <p><span style="font-size: 16px;">l 项目管理：实时多维度掌握项目进度、成本，实现智慧决策。</span></p>
        <p><span style="font-size: 16px;">l 标书制作管理：一键生成标书，高效高质完成标书制作。</span></p>
        <p><span style="font-size: 16px;">l 风险管理：客户征信标记分类。</span></p>
        <p><span style="font-size: 16px;">l 人事管理：基础人事档案、薪酬、请假和考勤、员工服务等基础人力资源管理，可以帮助企业实现从员工招募、入职、管理到离职的全面数字化管理，快速提升人才管理能力，提升人力资源管理效率，帮助员工快速成长，实现智慧决策。</span></p>
        <p>&nbsp;</p>
        <h2>三、鹰能的优势</h2>
        <p><br></p>
        <p><span style="font-size: 16px;"><strong style="font-size: 18px">1、两低三高：</strong>低建设投入、低运行成本、高水平服务、高安全保密、高可靠保障。</span></p>
        <p><span style="font-size: 16px;"><strong style="font-size: 18px">2、业务全覆盖：</strong>功能全面，满足业务；优化运营，实现双赢。</span></p>
        <p><span style="font-size: 16px;"><strong style="font-size: 18px">3、多种应用模式：</strong>通用型服务（基于阿里云/腾讯云的云上服务）；定制应用服务（围绕客户需求定制开发与云上、云下部署）。</span></p>
        <p>&nbsp;</p>
      </div>
    </div>
    <div class="wrap_ab_02">
      <div class="wrap_in">
        <ul class="td">
          <li v-for="item of youshiData" :key="item.icon">
            <a href="###">
              <em class="em1"><img :src="require('@/assets/images/about/'+item.icon)" alt=""></em>
              <em class="em2">
                <span class="sp1">{{ item.title }}</span>
                <em class="em3">
                  <block v-for="itemC1 in item.info" :key="itemC1.info">
                    <strong v-if="itemC1.title">
                      <em class="em3">{{ itemC1.title }}</em>
                    </strong>
                    <span class="sp3">{{ itemC1.info }}</span>
                  </block>
                </em>
              </em>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap_in wrap_ab_03">
      <Timeline></Timeline>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/Timeline";
export default {
  name: "index",
  components:{
    Timeline
  },
  data () {
    return {
      youshiData:[
        {
          icon:'ab_02.jpg',
          title:'使命/愿景',
          info:[
            {
              title:'使命',
              info:'科技成就安保企业'
            },
            {
              title:'愿景',
              info:'让安保拥有世界领先的管理能力'
            }
          ]
        },
        {
          icon:'ab_03.jpg',
          title:'经营管理原则',
          info:[
            {info:'聚焦压强'},
            {info:'长期主义'},
            {info:'向成功者学习'},
            {info:'端到端'},
            {info:'流程型组织建设'},
          ]
        },
        {
          icon:'ab_04.jpg',
          title:'核心价值观',
          info:[
            {info:'客户成功'},
            {info:'奋斗'},
            {info:'简单真诚'},
            {info:'专业'},
          ]
        },
        {
          icon:'ab_05.jpg',
          title:'领导力原则',
          info:[
            {
              title:'基层-执行到位的操心鹰豆',
              info:'责任、担当；结果导向；团队协作；敏锐学习；韧性'
            },
            {
              title:'中层-追求卓越的顶梁柱',
              info:'追求卓越、高标准；系统解决问题；协同增效；发展他人；拥抱变化'
            },
            {
              title:'高层-永远创业的奋斗者',
              info:'创业激情；建议系统；决策敏锐；建立成功团队；战略敏锐'
            }
          ]
        },
      ],
      historyData: [
        {
          date: '2021年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP'
        },
        {
          date: '2020年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP '
        },
        {
          date: '2019年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打实sdad啥地方撒大声地asdasda'
        },
        {
          date: '2018年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP 地方撒大声地asdasda'
        },
        {
          date: '2017年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打地asdasda'
        },
        {
          date: '2016',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storad啥地方撒大声地asdasda'
        },
        {
          date: '2015年',
          'title': 'Beisen Cloud正式对外开放，发布北森APP Storsdd实打实大所大大实打实sdad啥地声地asdasda'
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

.main {
  .banner-wrap,img{
    width: 100%;
  }
  .ab_01_t {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 60px;
    margin-top: 60px;

    span {
      display: inline-block;
      background-color: #fff;
      font-size: 36px;
      color: #111;
      line-height: 1.5;
      position: relative;
      z-index: 9;
      padding: 0 78px;
      text-align: center;
    }

    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, 50%);
      content: "";
      width: 100%;
      border-bottom: 1px solid #E3E3E3;
      display: block;
    }
  }

  .wrap_ab_01 {
    p {
      width: 100%;
      overflow: hidden;
      font-size: 14px;
      color: #111;
      line-height: 2;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 1.5em;
    }
  }

  .wrap_ab_02 {
    width: 100%;
    background: #F4F5FB;
    padding-top: 104px;

    .wrap_in {
      > ul {
        display: flex;

        li {
          width: 23.5%;
          overflow: hidden;
          float: left;
          margin-right: 2%;
          background-color: #fff;
          min-height: 512px;

          &:hover {
            background: $themeColor;
            box-shadow: 5px 5px 10px #A6C8E2FF;
            margin-top: -3px;

            span, em {
              color: #fff !important;
            }
          }

          em, span {
            display: block
          }

          .em1 {
            img {
              width: 100%;
              display: block;
            }
          }

          .em2 {
            width: auto;
            margin: 0.24rem 0.3rem;

            .sp1 {
              display: block;
              font-size: 24px;
              color: #111;
              line-height: 1.5;
              font-weight: bold;
            }
          }

          .em3 {
            display: table;
            font-size: 16px;
            color: #666;
            margin: 0.12rem 0;
            line-height: 1.5;

            .sp3 {
              padding: 0.04rem 0;
            }
          }
        }
      }
    }
  }
  .wrap_ab_03{
    background: #F4F5FB;
  }

}

</style>
