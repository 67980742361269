import { axios } from '@/utils/request'

const api = {
  portalArticle: '/portal/portalArticle'
}
export function getPortalArticle (id) {
  return axios({
    url: api.portalArticle + '/get/' + `${id}`,
    method: 'get'
  })
}

export function getPortalArticleList (parameter) {
  return axios({
    url: api.portalArticle + '/list',
    method: 'get',
    params: parameter
  })
}

export function getPortalArticleHrList (parameter) {
  return axios({
    url: api.portalArticle + '/hr/list',
    method: 'get',
    params: parameter
  })
}

export function savePortalArticle (parameter) {
  return axios({
    url: api.portalArticle + (parameter.id ? '/update' : '/save'),
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function delPortalArticle (parameter) {
  return axios({
    url: api.portalArticle + '/remove',
    method: 'post',
    params: parameter
  })
}

export const portalArticleExport = api.portalArticle + '/export'
