<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/solution/project/banner.jpg" alt="">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">项目管理解决方案</p>
          <p class="p2">全周期管理，提高项目绩效</p>
          <p class="p3">项目看板、成本管理、行业普适</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_pro_01">
      <h2 class="title">传统方式做项目管理，需破解4大难题</h2>
      <p class="p_info">面对越来越复杂的项目组织、越来越频繁的内外成员沟通、越来越多的项目知识输出、越来越难控的项目风险……传统项目管理仅将项目流程简单在线化，瓶颈渐显：</p>
      <ul class="mobile">
        <li class="mobile100 mfx" v-for="(item,i) in proArr1" :key="item.title">
          <img class="mw40" :src="imgUrl(`td0${i+1}.png`)">
          <div style="text-align: center">
            <h3>{{ item.title }}</h3>
            <p>{{ item.info }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_pro_02">
      <div class="wrap_in">
        <h2 class="title">全程数字化，提高项目绩效</h2>
        <p class="p_info">
          基于众多500强企业项目管理最佳实践，打造一站式的项目管理平台，支持项目全生命周期数字化管理，让项目过程管理更透明、多类成本更可控、知识分享更便捷、项目协作更高效，支撑项目组织防控风险、提升产能与绩效。</p>
        <img class="mw100" src="@/assets/images/solution/project/jiagou.png"/>
      </div>
    </div>
    <div class="wrap_pro_03 wrap_in">
      <h2 class="title">项目管理平台应用亮点</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="(item,i) in proArr2" :key="i">
          <h3>{{ item.title }}</h3>
          <p>{{ item.info }}</p>
          <div class="image_preview">
            <el-image :src="imgUrl(`pro03_0${i+1}.png`)" :preview-src-list="[imgUrl(`pro03_0${i+1}.png`)]"></el-image>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_pro_04">
      <div class="wrap_in">
        <h2 class="title">项目管理平台应用价值</h2>
        <p class="p_info">全程数字化的项目管理平台适用于国企、地产、金融、制造、快消等众多行业，已在上海建科、中国赛宝等企业应用，为企业创造了显著的价值。</p>
        <ul class="mobile">
          <li class="mw100" v-for="(item,i) in proArr3" :key="i">
            <el-image class="img" :src="imgUrl(`jz0${i+1}.png`)" fit="cover"></el-image>
            <h3 class="ellipsis">{{ item.title }}</h3>
            <p>{{ item.info }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",

  data () {
    return {
      proArr1: [
        {
          title: '项目进度管控难',
          info: '缺乏全周期在线管理，项目风险难管控'
        },
        {
          title: '项目管理成本高',
          info: '人力、费用等精细化管理不够，成本高'
        },
        {
          title: '项目知识共享难',
          info: '经验、知识分散，项目复用、分享不便'
        },
        {
          title: '项目协作效率低',
          info: '工具有限，项目成员跨组织协作支撑弱'
        },
      ],
      proArr2: [
        {
          title: '全程在线，项目高效推进',
          info: '支撑项目全周期在线化管理，对项目进行派员、临时撤员、撤点、变更、项目人员排班、项目执勤点、一点一方案及时操控。'
        },
        {
          title: '看板管理，项目全面掌控',
          info: '提供项目看板，支撑项目部成员及公司领导全面掌握项目动态，包括项目进度、计划、人员、成本、收款、合同等，让项目管理更高效'
        },
        {
          title: '一点一方案',
          info: '当项目过大，项目点过去繁杂时，实行一点一方案管理。'
        },
        {
          title: '工时管理，项目成本可控',
          info: '及时查看项目人员排班情况,对项目人工成本按工时分摊的方式，可以更灵活地处理不同设计院的需求，让项目的人力成本管理更精细、更可控'
        }
      ],
      proArr3: [
        {
          title: '全业务拉通，经营运营一体化',
          info: '打造了一个从市场经营、项目备案、投标、客户台账、销售、立项、策划、交付、收款的闭环经营运营系统，通过经营、运营一体化，将大大提升业务协同的效率'
        },
        {
          title: '全成本管理，提升精细化运营',
          info: '针对不同组织的人工成本分摊模式，系统提供了人工成本直接分摊和人工时分摊两种方式，可以很好满足不同组织粗放式和精细化管理的需求'
        },
        {
          title: '多数据看板，项目整体可掌控',
          info: '针对多类数据分散的现象，提供了完整的项目看板，将系统各模块数据完美整合在一起，可以非常直观的了解一个项目的全貌，便于进度管控与项目决策'
        },
      ]
    }
  },
  computed: {
    imgUrl () {
      return imgName => require(`@/assets/images/solution/project/${imgName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

h2.title {
  text-align: center;
  color: #20242b;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
}

p.p_info {
  margin: 40px auto;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737c8b;
}

.wrap_pro_01 {
  margin: 100px 0;
  overflow: hidden;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 50%;
      }

      h3 {
        color: #363f4e;
        font-size: 20px;
        line-height: 24px;
        margin: 10px 0;
      }

      p {
        color: #737c8b;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.wrap_pro_02 {
  background: #F7F8FB;
  padding: 100px 0;

  p {
    width: 80%
  }

  img {
    display: block;
    width: 100%;
    margin: 60px auto 0;
  }
}

.wrap_pro_03 {
  margin: 100px 0 0;
  overflow: hidden;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;

    li {
      width: 48%;
      margin-bottom: 80px;

      h3 {
        color: #363f4e;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      p {
        color: #737c8b;
        font-size: 16px;
        line-height: 24px;
      }

      .image_preview {
        width: 100%;
        margin-top: 30px;
        border: 1px solid #F3F3F3;
        padding: 1%;
      }
    }
  }
}

.wrap_pro_04 {
  background: #F7F8FB;
  padding: 100px 0;

  ul {
    display: flex;
    justify-content: space-between;

    li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {
        width: 50%;
      }

      h3 {
        height: 70px;
        color: #20242b;
        font-size: 20px;
        line-height: 70px;
        text-align: center;
      }

      p {
        color: #737c8b;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
</style>
