<template>
  <div class="main">
    <div class="banner-wrap">
      <img src="@/assets/images/about/join/banner.jpg">
      <div class="wrap_in">
        <h2>加入我们</h2>
        <h3>JOIN US</h3>
      </div>
    </div>
    <div class="wrap_join_01 wrap_in">
      <h1>加入我们</h1>
      <div class="wrap_info">
        <div class="wrap_item mobile">
          <img class="fl mobile100" src="@/assets/images/about/join/n_join_fl01_img01.jpg">
          <div class="fr con mobile100 pl0" style="padding-left: 84px;">
            <img src="@/assets/images/about/join/n_join_fl01_icon01.png">
            <h4>有竞争力的薪酬</h4>
            <p>
              公司倡导并实践基于绩效的薪酬体系。每位员工的薪资水平和他们自己的职业发展以及对公司的贡献密切相关。公司为员工提供行业内有市场竞争力的薪资福利，并根据每年的市场薪酬水平变化和个人业绩表现情况对员工的工资进行年度薪酬调整。对公司需要的优秀人才，将提供更具吸引力的薪资。除了固定工资之外，公司还会根据员工在该年度工作业绩表现及公司年度目标达成情况发放奖金以激励员工。</p>
          </div>
        </div>
        <div class="wrap_item mobile">
          <img class="fr mobile100" src="@/assets/images/about/join/n_join_fl01_img02.jpg">
          <div class="fl con mobile100 pr0 moder0" style="padding-right: 84px;order: -1">
            <img src="@/assets/images/about/join/n_join_fl01_icon02.png">
            <h4>人性化的福利政策</h4>
            <p>除了按照国家和地方政府的规定为员工缴纳社保和公积金，我们还提供丰富的补充福利，为我们的员工及家人解决后顾之忧。<br/>
              * 午餐补助 * 差旅补贴 * 团建 * 通讯补助<br/>
              * 加班补助 * 完善的职业发展路径<br/>
              新员工导师制度确保每一位新加入的同事在最短时间内适应公司的文化和工作环境，健全的员工发展路径指导并帮助每一位员工在融智通找到适合自己的职业发展路径并充分获得自我提升。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap_join_02">
      <div class="wrap_in">
        <div class="wrap_title">
          <h2>招聘岗位</h2>
          <h3>RECRUITMENT</h3>
        </div>
        <div class="wrap_info mobile">
          <div class="fl mobile100 mhide">
            <img src="@/assets/images/about/join/n_join_bg.jpg">
          </div>
          <div class="fr mobile100">
            <div class="wrap_item" :class="{active:recruitmentIndex===index}" v-for="(item,index) in HrList"
                 :key="item.id">
              <a href="javascript:;">
                <div class="title_wrap" @click="recruitmentToggle(index)">
                  <h5>{{ item.recruitNum }}-{{ item.deptName }}</h5>
                  <img :src="imgUrl(recruitmentIndex===index?'jian01_on':'jian01')"/>
                </div>
              </a>
              <div class="content_wrap" :style="{height:recruitmentIndex===index?recruitmentHeight+'px':0}">
                <div class="content" :ref="'recruitment'+index">
                  <h5>基本信息：</h5>
                  <p>
                    &nbsp;&nbsp;招聘岗位：{{ item.recruitNum }}<br>
                    &nbsp;&nbsp;招聘人数：{{ item.recruitPosition }}<br>
                    &nbsp;&nbsp;招聘区域：{{ item.jobDescription }}<br>
                    &nbsp;&nbsp;工作时间：{{ item.workingTime }}<br>
                    &nbsp;&nbsp;招聘工期：{{ item.recruitType }}<br>
                    &nbsp;&nbsp;联系人：{{ item.contact }}/{{ item.telephone }}
                  </p>
                  <h5>薪资待遇：</h5>
                  <p v-html="item.salary"></p>
                  <h5>岗位职责：</h5>
                  <p v-html="item.recruitArea"></p>
                  <h5>招聘要求：</h5>
                  <p v-html="item.requirement"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getPortalArticleHrList} from "@/api/portal/portalArticle";

export default {
  name: "index",
  data () {
    return {
      pageSize: 10,
      pageNum: 1,
      HrList: [],
      recruitmentIndex: 0,
      recruitmentHeight: 0,
    }
  },
  computed: {
    imgUrl () {
      return url => require(`@/assets/images/about/join/${url}.png`);
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.getPortalHrList()
      await this.setRecruitmentH(this.recruitmentIndex)
    },

    setRecruitmentH (index) {
      this.$nextTick(() => {
        this.recruitmentHeight = this.$refs['recruitment' + index][0].clientHeight
      })
    },
    recruitmentToggle (index) {
      this.recruitmentIndex = index === this.recruitmentIndex ? '' : index
      this.setRecruitmentH(index)
    },
    async getPortalHrList () {
      const {pageSize, pageNum} = this
      let list = await getPortalArticleHrList({pageSize, pageNum})
      this.HrList = list.rows
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .banner-wrap {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .wrap_in {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 40px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 56px;
        color: rgba(255, 255, 255, 0.55);
      }
    }
  }

  .wrap_join_01 {
    padding-top: 40px;

    > h1 {
      height: 60px;
      border-bottom: #e6e6e6 3px solid;
      font-size: 32px;
      color: #373737;
      font-weight: 400;
    }

    .wrap_info {
      margin-top: 56px;
      overflow: hidden;

      .wrap_item {
        display: flex;
        overflow: hidden;

        .fl, .fr {
          width: 50%;
        }

        .con {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px 0;

          img {
            width: 37px;
            height: 33px;
          }

          h4 {
            font-size: 24px;
            margin: 10px 0 28px;
            color: #4c4c4c;
            font-weight: 400;
          }

          p {
            font-size: 16px;
            color: #4c4c4c;
            line-height: 32px;
          }
        }
      }

    }
  }

  .wrap_join_02 {
    width: 100%;
    background: #F6F6F6;
    padding-top: 75px;
    padding-bottom: 64px;

    .wrap_title {
      text-align: center;
      margin-bottom: 44px;

      h2 {
        font-size: 32px;
        color: #303030;
      }

      h3 {
        font-size: 28px;
        margin-top: 10px;
        color: #dcdcdc;
      }
    }

    .wrap_info {

      display: flex;
      justify-content: space-between;

      .fl {
        width: 325px;

        img {
          width: 100%;
        }
      }

      .fr {
        width: calc(100% - 365px);

        .wrap_item {
          margin-bottom: 10px;

          &.active {
            .title_wrap {
              background: #0068EB;
              border: #0068EB 1px solid;

              h5 {
                color: #FFFFFF;
              }
            }

            .content_wrap {
              transition: height .5s;
            }
          }

          .title_wrap {
            height: 85px;
            padding: 0 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: #e9e9e9 1px solid;
            background: #FFFFFF;

            h5 {
              font-size: 22px;
              color: #606162;
              font-weight: 400;
            }

            img {
              width: 17px;
            }
          }

          .content_wrap {
            height: 0;
            overflow: hidden;
            transition: height .5s;

            .content {
              background: #FFFFFF;
              line-height: 35px;
              padding: 48px;
            }
          }
        }
      }
    }
  }
}
</style>
