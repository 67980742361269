<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/solution/rck/banner.jpg" alt="">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">人才库建设方案</p>
          <p class="p3">企业提供内外部人才库建设的一整套解决方案<br>
            助力企业盘活内外部人才库，实现人力资源整合复用与高价值人才挖掘跟踪，为企业发展</p>
        </div>
      </div>
    </div>
    <div class="wrap_in">
      <div class="wrap_rck_01">
        <ul class="center_nav">
          <li @click="choiceRck(0)"><a href="javascript:;" :class="{active:rcNavTab===0}">人才库的标准、分类</a></li>
          <li @click="choiceRck(1)"><a href="javascript:;" :class="{active:rcNavTab===1}">人才库运营结果应用</a></li>
        </ul>
        <div class="nav_con">
          <img :src="imgUrl()" alt="人才库的标准、分类">
        </div>
      </div>
      <div class="wrap_rck_02">
        <h2>人才库价值分析</h2>
        <div class="con">
          <img src="@/assets/images/solution/rck/rck_val_bg.jpg" alt="人才库价值">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Rck",
  data () {
    return {
      rcNavTab:0
    }
  },
  computed: {
    imgUrl () {
      return () => {
        const i = this.rcNavTab?2:3
        return require(`@/assets/images/solution/rck/n_rck_con1_${i}.jpg`)
      }
    }
  },
  methods: {
    choiceRck (i) {
      this.rcNavTab = i;
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  background: #F8F9FB;
  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      .wrap_in {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #FFFFFF;

        .p1 {
          font-size: 36px;
          margin-bottom: 20px;
        }

        .p2 {
          font-size: 46px;
          margin-bottom: 30px;
        }

        .p3 {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  .wrap_rck_01{
    margin-top: 70px;
    .center_nav{
      margin: 0 auto;
      width: 80%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eaeaea;
      box-shadow: 0 5px 5px #E8E9EE;
      li{
        width: 50%;
        text-align: center;
        line-height: 100px;
        font-size: 16px;
        color: #333333;
        position: relative;
        a{
          display: block;
          &:hover,&.active{
            background: $themeColor;
            color: #FFFFFF;
            &:after{
              bottom: -10px;
              border-top-color: $themeColor;
            }
          }
          &:after{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 14px 0 14px;
            border-color: transparent transparent transparent transparent;
            position: absolute;
            left: 50%;
            bottom: 0;
          }
        }
      }
    }
    .nav_con{
      width: 100%;
      padding: 80px;
      background: #FFFFFF;
      img{
        width: 100%;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .wrap_rck_02{
    padding-bottom: 50px;
    h2{
      padding: 100px 0 70px;
      font-size: 28px;
      font-weight: normal;
      text-align: center;
    }
    .con{
      background: #FFFFFF;
      img{
        width: 100%;
      }
    }
  }
}
</style>
