<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/solution/bid/banner.jpg">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">电子标书一键制作</p>
          <p class="p3">轻松优越的互联网，满足您的投标愿望</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_bid_01">
      <h2 class="title">电子标书制作流程</h2>
      <ul class="mobile">
        <li class="mobile100 mfx">
          <b>01</b>
          <div class="fonts">
            <h3>在线报名</h3>
            <p>平台注册，CA锁申请</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <b>02</b>
          <div class="fonts">
            <h3>下载招标文件</h3>
            <p>项目报名，下载招标文件</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <b>03</b>
          <div class="fonts">
            <h3>标书制作</h3>
            <p>软件版投标文件制作</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <b>04</b>
          <div class="fonts">
            <h3>导入专属存储器</h3>
            <p>安全至上，签章、加密、上传</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <b>05</b>
          <div class="fonts">
            <h3>开标</h3>
            <p>进行网上开标环节操作</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <b>06</b>
          <div class="fonts">
            <h3>数据是实时查询</h3>
            <p>开标结束，可查询并打印开标数据</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_in wrap_bid_02">
      <h2 class="title">服务范围</h2>
      <ul class="mobile">
        <li class="mobile100 mfx">
          <img src="@/assets/images/solution/bid/2_01.png">
          <div class="fonts">
            <h3>采标平台注册</h3>
            <p>建立可信安全的物理运行环境，保障各种实体的安全</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img src="@/assets/images/solution/bid/2_02.png">
          <div class="fonts">
            <h3>投标项目报名</h3>
            <p>建立协同工作平台，实现业务自动流转</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img src="@/assets/images/solution/bid/2_03.png">
          <div class="fonts">
            <h3>软件版投标</h3>
            <p>安全保障系统，解决网上招投标安全性问题</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img src="@/assets/images/solution/bid/2_04.png">
          <div class="fonts">
            <h3>验收环节</h3>
            <p>提供有效、详细的操作日志记录和审计功能</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_in wrap_bid_03">
      <h2 class="title">服务优势</h2>
      <ul class="mobile">
        <li class="mw100">
          <img src="@/assets/images/solution/bid/3_01.png">
          <div class="fonts">
            <h3>大数据支撑，上万份分模块化标书数据，覆盖50大行业</h3>
            <p>
              1、 一对一服务大数据支撑
              2、规避招标文件中隐 蔽陷阱
              3、数千家 成功中标案例数据库
              4、杜绝细小错误 导致得废标和扣分
              5、迎合评标委员会和甲方的审美标准
            </p>
          </div>
        </li>
        <li class="mw100">
          <img src="@/assets/images/solution/bid/3_02.png">
          <div class="fonts">
            <h3>贴心精准的预算，给您一份精准报告</h3>
            <p>
              1、汇集百位造价师
              2、各种正版预算软件，全国定额
              3、工程量清 单编制，招标控制价编制
              4、各类工程定额预算，清单预算
              5、专家组审核
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

h2.title {
  text-align: center;
  color: #20242b;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
}

.wrap_bid_01 {
  margin: 80px 0;
  overflow: hidden;

  ul {
    padding: 60px 0;
    //width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 30%;
      text-align: center;
      padding-bottom: 20px;

      b {
        font-weight: bold;
        font-size: 60px;
        color: $themeColor;
      }

      h3 {
        font-size: 18px;
        color: #363f4e;
        font-weight: 600;
        margin: 10px 0;
      }

      p {
        font-size: 15px;
        color: #6e6e6e;
        line-height: 28px;
      }
    }
  }
}

.wrap_bid_02 {
  margin-top: 80px;
  ul {
    //width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 0;
      text-align: center;

      img {
        width: 100px;
        height: 100px;
      }

      .fonts{
        h3 {
          font-size: 18px;
          color: #363f4e;
          font-weight: 600;
          margin: 10px 0;
        }

        p {
          font-size: 15px;
          color: #6e6e6e;
          line-height: 28px;
        }
      }
    }
  }
}

.wrap_bid_03 {
  margin-top: 80px;
  ul {
    //width: 70%;
    margin: 60px auto;
    display: flex;
    justify-content: space-between;

    li {
      width: 45%;
      border: 1px solid #DDDDDD;

      img{
        width: 100%;
      }
      .fonts{
        padding: 20px;
        h3 {
          font-size: 18px;
          color: #363f4e;
          font-weight: 600;
          margin-bottom: 20px;
        }

        p {
          font-size: 15px;
          color: #6e6e6e;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
