import { axios } from '@/utils/request'

const api = {
  portalArticleCate: '/portal/portalArticleCate'
}

export function getPortalArticleCate (id) {
  return axios({
    url: api.portalArticleCate + '/get/' + `${id}`,
    method: 'get'
  })
}

export function getPortalArticleCateList (parameter) {
  return axios({
    url: api.portalArticleCate + '/list',
    method: 'get',
    params: parameter
  })
}

export function savePortalArticleCate (parameter) {
  return axios({
    url: api.portalArticleCate + (parameter.id ? '/update' : '/save'),
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function delPortalArticleCate (parameter) {
  return axios({
    url: api.portalArticleCate + '/remove',
    method: 'post',
    params: parameter
  })
}

export const portalArticleCateExport = api.portalArticleCate + '/export'
