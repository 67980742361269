<template>
  <div class="main">
    <div class="wrap_ct_01">
      <img src="@/assets/images/about/ab_01.jpg" alt="">
    </div>
    <div class="wrap wrap_ct_02">
      <div class="wrap_in">
        <h2>联系鹰能</h2>
        <ul class="mobile">
          <li class="mobile100" v-for="item of aboutWay" :key="item.title">
            <img src="@/assets/images/about/ab_18.jpg" alt="">
            <h3>{{item.title}}</h3>
            <a href="">{{ item.tel }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap wrap_ct_03">
      <div class="wrap_in">
        <h2>公司地址</h2>
        <div class="info mobile">
          <div class="map mobile100">
            <baidu-map
                id="allmap"
                @ready="mapReady"
                :scroll-wheel-zoom="true"
            >
              <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
              <bm-marker :position="point" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
            </baidu-map>
          </div>
          <div class="wrap_addr mobile100">
            <p class="p1">
              <span>公司地址</span>
            </p>
            <p class="p2">
              <i class="iconfont icon-address"></i>
              <a href="">地址：云南省昆明市环城东路455号
              </a>
            </p>
            <p class="p3">
              <i class="iconfont icon-tel"></i>
              咨询热线：13518786721
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data(){
    return {
      point:{
        lng:102.73649571862902,
        lat:25.038855723235095
      },
      aboutWay:[
        {
          title:'售前电话',
          tel:'13518786721'
        },
        {
          title:'售后电话',
          tel:'13518786721'
        },
        {
          title:'市场合作',
          tel:'418329339@qq.com'
        },
      ],
    }
  },
  methods:{
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点 102.73649571862902,25.038855723235095
      this.point = new BMap.Point(102.73649571862902,25.038855723235095);
      map.centerAndZoom(this.point, 12);
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap_ct_01{
  width: 100%;
  img{width: 100%}
}
.wrap{
  padding-top: 88px;
  padding-bottom: 90px;
  h2{
    font-size: 44px;
    text-align: center;
    padding-bottom: 13px;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 18px;
      height: 4px;
      background: $themeColor;
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
    }
  }
}
.wrap_ct_02{
  background: #F4F5FB;
  ul{
    display: flex;
    justify-content: space-between;
    margin-top: 51px;
    li{
      width: 30%;
      margin-right: 5%;
      background: #FFFFFF;
      padding: 50px 0 65px 0;
      img{
        display: block;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin: 0 auto;
      }
      h3{
        font-size: 30px;
        margin-top: 17px;
        text-align: center;
        position: relative;
        height: 76px;
        line-height: 76px;
        &:after{
          content: "";
          position: absolute;
          width: 15px;
          height: 3px;
          background: $themeColor;
          left: 50%;
          bottom: 0;
          transform: translate(-50%);
        }
      }
      a{
        display: block;
        font-size: 24px;
        color: #888888;
        margin-top: 30px;
        text-align: center;
      }
      &:last-child{
        margin-right: 0;
      }
      &:hover{
        margin-top: -3px;
      }
    }
  }
}
.wrap_ct_03{
  margin-bottom: 44px;
  .info{
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    background: #f4f5fb;
    .map{
      width: 44%;
      height: 472px;
      overflow: hidden;
      background: #f4f5fb;
      #allmap{
        height: 100%;
        width: 100%;
        margin: 0 auto;
      }
    }
    .wrap_addr{
      width: 47.5%;
      margin-right: 66px;
      .p1{
        margin: 53px 0 33px;
        border-bottom: 1px solid #d7d7d7;
        span{
          display: inline-block;
          height: 67px;
          line-height: 67px;
          font-size: 28px;
          position: relative;
          &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background: $themeColor;
            left: 0;
            bottom: -1px;
          }
        }
      }
      .p2,.p3{
        display: flex;
        height: 37px;
        align-items: center;
        font-size: 18px;
        i{
          font-size: 22px;
          color: $themeColor;
          margin-right: 10px;
        }
        a:hover{
          color: $themeColor;
        }
      }
    }
  }
}

</style>
