<template>
  <div>
    <el-dropdown placement="bottomRight">
      <div>
        <span class="el-dropdown-link">
          <el-row>
            <el-col :span=4>
               <el-avatar size="small" :src="downloadAction+avatar"/>
            </el-col>
               <el-col :span=19 :offset=1 class="name">
                 <span>{{ nickname }}</span>
            </el-col>
          </el-row>
          <div class="avatar">
          </div>
        </span>
      </div>
      <el-dropdown-menu slot="dropdown" style="margin-top: 40px">
        <div @click="handleLogout">
          <el-dropdown-item>
            <!--          <i class="el-icon-logout"></i>-->
            退出登录
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'AvatarDropdown',
  props: {},
  data() {
    return {
      downloadAction: process.env.VUE_APP_FILE_DOWNLOAD_URL
    }
  },
  computed: {
    ...mapGetters(['nickname', 'avatar'])
  },
  methods: {
    ...mapActions(['Logout']),
    // handleToCenter() {
    //   this.$router.push({path: '/account/center'})
    // },
    // handleToSettings() {
    //   this.$router.push({path: '/account/settings'})
    // },
    handleLogout(e) {
      return this.Logout({})
          .then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 16)
          })
          .catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            })
          })
      // Modal.confirm({
      //   title: '提示',
      //   content: '你真的要离开了嘛',
      //   onOk: () => {
      //     return this.Logout({})
      //         .then(() => {
      //           setTimeout(() => {
      //             window.location.reload()
      //           }, 16)
      //         })
      //         .catch(err => {
      //           this.$message.error({
      //             title: '错误',
      //             description: err.message
      //           })
      //         })
      //   },
      //   onCancel() {
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.name {
  margin-top: 5px;
  font-size: 15px;
}
</style>
