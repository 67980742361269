import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user
    },
    state: {
        globleMask: false
    },
    mutations: {
        changeGlobleMask(state) {
            state.globleMask = !state.globleMask
        }
    },
    actions: {},

    getters
})
