<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/product/train/banner.jpg">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">保安人员培训</p>
          <p class="p3">文明执勤，维护企业、公司形象，熟悉工作职责，规范工作行为</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_dp_02">
      <h2>保安服务现状</h2>
      <p>保安服务业的崛起,使我国的整个社会治安形势得到了明显好转,治安案件稳中有降,突出治安问题得到了有效控制,治安重点地区得到了认真整治,治安防范水平明显提高,广大人民群众的安全感进一步增强。随着保安队伍的日益增长,人员数量的突出猛增,保安员年龄结构的参差不齐,保安队伍的自身形象、综合素质、服务质量都急需有待于进一步提高和加强。这就需要保安服务业加快保安培训的工作进程,只有加强保安培训,提升保安队伍的整体素质,才能规范保安队伍的全面建设,才能引领保安行业的前进脚步。</p>
    </div>
    <div class="wrap_in wrap_dp_02" v-for="(item,index) in data" :key="index">
      <h2>{{ item.title }}</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="(child,i) in item.content" :key="i">
          <a href="javascript:;" class="mfx">
            <div class="img_wrap">
              <img :src="require(`@/assets/images/product/train/0${index+1}_0${i+1}.jpg`)">
            </div>
            <div class="font_wrap">
              <p class="ellipsis4">{{ child }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      data: [
        {
          title: '保安员的守则',
          icon:true,
          content: [
            "1、遵守国家政策法令、法规，遵守市民行为道德规范，遵守本院一切规章制度。",
            "2、服从领导、听从指挥、团结同事、互帮互助，按时按质完成各项工作任务。",
            "3、严格遵守劳动纪律，按质量标准要求开展工作，作业场所禁止无关人员逗留。",
            "4、讲究社会公德和职业道德，严守公司机密，廉洁奉公，维护集体利益和公司声誉。",
            "5、爱护公物及公用设施，自觉维护和保持环境卫生。",
            "6、勤俭办公，节约用水、用电，杜绝一切浪费现象。",
            "7、衣容整洁、精神饱满、待人热情、文明用语。",
            "8、文明礼貌，保持良好的岗位形象。"
          ]
        },
        {
          title:'工作态度',
          icon:true,
          content: [
            "1、服从领导――不折不扣地服从上级的工作安排及工作调配。",
            "2、严于职守――坚守本职岗位，不得擅自离岗、串岗或睡岗。",
            "3、团结协作――各部门之间、员工之间要互相配合，同心协力地解决困难。",
            "4、勤勉高效――发扬勤奋踏实的精神，优质高效地完成所担负的工作。",
            "5、认真学习――认真学习相关专业知识（包括但不限于：安保和消防等），不断提高自身业务水平，努力提高服务质量。",
            "6、勤俭节约--具有良好的节约意识，节约为公，节约能源",
            "7、正直诚实――对上级领导、同事和客户要以诚相待，不得阳奉阴违。",
          ]
        },
        {
          title:'服务态度',
          content: [
            "1、礼貌――这是员工对客户和同事最基本的态度，在任何时刻均应使用礼貌用语，“请”字当头、“谢”字不离口。",
            "2、乐观――以乐观的态度接待客户。",
            "3、友善――“微笑”是体现友善最适当的表达方式，因此以微笑来迎 接客户及与同事相处。",
            "4、热情――尽可能为同事和客户提供方便，热情服务。",
            "5、耐心――对客户的要求应认真、耐心地聆听。",
            "6、平等――视同仁地对待所有病人，不能有贫富之分、厚此薄彼。",
          ]
        },
        {
          title:'仪容仪表',
          content: [
            "（一） 着装 1、 上岗着装保持干净整洁，扣好纽扣，不得敞开外衣，卷起裤脚、衣袖，领带必须结正。 2、 制服外衣衣袖、衣领处，制服衬衣领口，不得显露个人衣物、饰品，制服外不得显露与工作无关的个人物品，衣袋内不得装过大过厚物品。 3、 除因工作或经批准外，不准穿着或携带制服离开辖区，下班后不得穿制服。",
            "（二） 仪容仪表 1 精神振作、姿态端正，不准留长发、大鬓角，胡须 2 着装整洁，帽子戴正，扣好纽扣。不得敞衣露怀，卷袖挽裤腿，内衣不得外露，手机、锁匙扣 3 物品不得露于衣服外，衣服口袋不得装较大物品。 4 执勤时要佩戴好工作证和执勤器具。",
            "（三） 行为举止 1 举止文明、大方、端庄、稳健。 2 用语文明，谈话自然。 3 精神振作，姿态良好。抬头挺胸，不得弓腰驼背，不得东倒西歪，前倾后靠，不得伸懒腰；不袖手、背手叉腰或将手插入衣袋内，执勤时不准吸烟、吃零食，不得搭肩挽背。 4 不得哼歌曲，吹口哨、跺脚。 5 不得随地吐痰，乱丢杂物。 6 注意检查和保持仪表，但不得当众整理个人衣物。 7 不得将任何物品夹于腋下，不得敲桌椅或玩弄其他物品。",
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.wrap_dp_02 {
  h2 {
    font-size: 30px;
    color: #20242b;
    padding-top: 40px;
    font-weight: 400;
    height: 50px;
    border-bottom: #e6e6e6 1px solid;
  }
  p{
    font-size: 15px;
    color: #6e6e6e;
    line-height: 28px;
    padding: 30px 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 30px;

    li {
      box-sizing: border-box;
      width: 32%;
      margin-bottom: 25px;
      border: #ddd solid 1px;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .img_wrap {
        background: #E7EEF8;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 0;
        padding-bottom: 60%;
        position: relative;

        &:hover {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 5;
          }

          img {
            animation: changeScale 1s;
          }
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          object-fit: cover;
        }
      }

      .font_wrap {
        width: 100%;
        padding: 32px;

        h3 {
          font-size: 18px;
          color: #383838;
          margin-bottom: 12px;

          &:hover {
            color: $themeColor
          }
        }

        p {
          font-size: 15px;
          color: #6e6e6e;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
