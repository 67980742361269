<template>
  <div>
    <Nav/>
    <router-view v-if="isShowNavFooter"/>
    <Footer/>
  </div>
</template>

<script>
import Nav from "./Nav";
import Footer from "./Footer";

export default {
  name: "index",
  components: {
    Nav,
    Footer
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isShowNavFooter: true,
    }
  },
  created() {
    let routePath = this.$route.path
    console.log(this.$route)
    if (routePath.trim() === '/' || routePath.trim() === '') {
      this.isShowNavFooter = false
    }
  },
  methods: {
    reload() {
      console.log('aaaaaa')
      this.isShowNavFooter = false
      this.$nextTick(() => {
        this.isShowNavFooter = true
      })
    }
  }
}
</script>

<style scoped>

</style>
