<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/product/finance/banner.jpg" alt="">
      <div class="box">
        <div class="wrap_in">
          <h1>财务管理平台</h1>
          <p>建立涵盖“预算管理、事前申请、借还款管理、员工报销、付款管理、台账管理、凭证中心”等一体化的费用管控平台，为企业/机构提供线上化、智能化的费控服务</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_hr_01">
      <h2 class="title">企业费控面临的难题</h2>
      <ul class="mobile">
        <li class="mobile100 mfx">
          <img class="mw30" src="@/assets/images/product/finance/td01.png">
          <div class="fonts">
            <p>预算控制难</p>
            <p>成本常失控</p>
            <p>预算调整灵活性差</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img class="mw30" src="@/assets/images/product/finance/td02.png">
          <div class="fonts">
            <p>重复工作累</p>
            <p>手工账单苦</p>
            <p>财务效能低转型慢</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img class="mw30" src="@/assets/images/product/finance/td03.png">
          <div class="fonts">
            <p>费用标准乱</p>
            <p>出差垫资多</p>
            <p>贴票报销员工心烦</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img class="mw30" src="@/assets/images/product/finance/td04.png">
          <div class="fonts">
            <p>数据统计慢</p>
            <p>费用成本高</p>
            <p>风险难控领导埋怨</p>
          </div>
        </li>
        <li class="mobile100 mfx">
          <img class="mw30" src="@/assets/images/product/finance/td05.png">
          <div class="fonts">
            <p>业财税分离</p>
            <p>平台支撑弱</p>
            <p>决策制度落地困难</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_hr_02">
      <div class="wrap_in">
        <h2 class="title">费控框架功能图</h2>
        <img src="@/assets/images/product/finance/kuangjia.jpg">
      </div>
    </div>
    <div class="wrap_in wrap_hr_03">
      <h2 class="title">多场景、多行业应用，赋能组织内外协同更高效</h2>
      <ul class="mobile">
        <li class="mobile100 mfx" v-for="(item,i) in part3Data" :key="item.title">
          <div class="img_wrap">
            <img :src="require(`@/assets/images/product/finance/lc0${i+1}.png`)">
          </div>
          <div class="font_wrap">
            <h3>{{ item.title }}</h3>
            <p class="ellipsis4">{{ item.info }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_hr_04">
      <div class="wrap_in">
        <h2 class="title">打造员工、财务、领导都满意的费控体验</h2>
        <ul class="mobile">
          <li class="mobile100 mfx" v-for="(item,i) in part4Data" :key="i">
            <div class="img_wrap">
              <img :src="require(`@/assets/images/product/finance/js0${i+1}.png`)">
            </div>
            <div class="font_wrap">
              <h3>{{ item.title }}</h3>
              <p class="ellipsis4">{{ item.info }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      part3Data: [
        {
          title: '1、灵活可控的预算管控',
          info: '多维预算、预算调整、执行台账、预算报表'
        },
        {
          title: '2、建立费用事前申请',
          info: '立项申请、出差申请、招待申请、借/还款申请'
        },
        {
          title: '3、员工报销当天到账',
          info: '在线审批、预算扣减、事前关联、冲抵借款'
        },
        {
          title: '4、关联合同条款直接付款',
          info: '合同台账、付款审批、预算关联、冲预付款'
        },
        {
          title: '5、系统自动推送电子凭证',
          info: '预制凭证、修改凭证、合并凭证、推送凭证'
        },
        {
          title: '6、多样的可视化报表呈现',
          info: '费用统计、预算执行、借款统计、报表钻取'
        },
        {
          title: '7、实现多个生态场景深度对接',
          info: '携程商旅、滴滴出行、银企直联、在线贴现'
        },
        {
          title: '8、智能工具打造便捷体验',
          info: 'OCR识别、审单机器人、智能问答机器人'
        },
      ],
      part4Data: [
        {
          title: '员工：“快速、省心的报销体验”',
          info: '申请方便、集成差旅、无需垫款、审批快速、报销快速到账'
        },
        {
          title: '管理层：“可视化的决策体验”',
          info: '预算可控、随时随地审批、掌握开支、费用合理、数据分析'
        },
        {
          title: '财务：“避免做重复性工作”',
          info: '票据核验、线上审核、报表生成、凭证推送、快速付款'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  h2.title {
    font-size: 30px;
    color: #20242b;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 300;
  }

  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      .wrap_in {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #FFFFFF;

        h1 {
          font-size: 40px;
          font-weight: 300;
          margin-bottom: 45px;
        }

        p {
          max-width: 550px;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }

  .wrap_hr_01 {
    margin: 80px 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        width: 12%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 100%;
          margin-bottom: 10px;
        }

        p {
          text-align: center;
          font-size: 16px;
          color: #737c8b;
          margin-bottom: 5px;
          font-weight: 300;
        }
      }
    }
  }

  .wrap_hr_02 {
    background: #F7F8FB;
    overflow: hidden;
    padding: 80px 0;

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  .wrap_hr_03 {
    margin: 80px 0;

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        width: 23.5%;
        margin-right: 2%;
        margin-bottom: 20px;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;

        &:nth-child(4n) {
          margin-right: 0;
        }

        .img_wrap {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            height: 200px;
          }
        }

        .font_wrap {
          width: 100%;
          padding: 32px;

          h3 {
            font-size: 18px;
            color: #383838;
            margin-bottom: 12px;

            &:hover {
              color: $themeColor
            }
          }

          p {
            font-size: 15px;
            color: #6e6e6e;
            line-height: 28px;
          }
        }
      }
    }
  }

  .wrap_hr_04 {
    background: #F7F8FB;
    overflow: hidden;
    padding: 80px 0;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        width: 30%;

        .img_wrap {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50%;
            margin-bottom: 20px;
          }
        }

        .font_wrap {
          width: 100%;
          padding: 32px;

          h3 {
            text-align: center;
            font-size: 18px;
            color: #383838;
            margin-bottom: 12px;

            &:hover {
              color: $themeColor
            }
          }

          p {
            text-align: center;
            font-size: 15px;
            color: #6e6e6e;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
