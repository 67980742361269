import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import {isEmpty} from "@/utils/util";

/* has token */
if (storage.get(ACCESS_TOKEN)) {
    const a = store
    console.log(a)
    if (isEmpty(store.getters.userId)) {
        store.dispatch('GetInfo')
            .catch(() => {
                store.dispatch('Logout').then(() => {
                })
            })
    }
}

//
// router.beforeEach((to, from, next) => {
//     NProgress.start() // start progress bar
//     const a = to
//     console.log(to)
//     // to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`))
//     /* has token */
//     if (storage.get(ACCESS_TOKEN)) {
//         /* has token */
//         // if (to.path === loginRoutePath) {
//         //     next({path: defaultRoutePath})
//         //     NProgress.done()
//         // } else {
//         //     if (store.getters.roles.length === 0) {
//         //         store.dispatch('GetInfo')
//         //             .then(() => {
//         //                 // 调用 vuex 的 从后端获取用户的路由菜单，动态添加可访问路由表
//         //                 store.dispatch('GenerateRoutes').then(() => {
//         //                     // 把已获取到的路由菜单加入到路由表中
//         //                     router.addRoutes(store.getters.addRouters)
//         //                     const redirect = decodeURIComponent(from.query.redirect || to.path)
//         //
//         //                     // 不加载重定向页面
//         //                     next({...to, replace: true})
//         //                     // if (to.path === redirect) {
//         //                     //   // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
//         //                     //   next({ ...to, replace: true })
//         //                     // } else {
//         //                     //   // 跳转到目的路由
//         //                     //   next({ path: redirect })
//         //                     // }
//         //                 })
//         //             })
//         //             .catch(() => {
//         //                 // notification.error({
//         //                 //   message: '错误',
//         //                 //   description: '请求用户信息失败，请重试'
//         //                 // })
//         //                 store.dispatch('Logout').then(() => {
//         //                     // next({ path: loginRoutePath, query: { redirect: to.fullPath } })
//         //                 })
//         //             })
//         //     } else {
//         //         next()
//         //     }
//         // }
//         next()
//     } else {
//         // next({...to, replace: true})
//         // if (to.path === redirect) {
//         //     // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
//         //     next({...to, replace: true})
//         // } else {
//         //     // 跳转到目的路由
//         //     next({path: redirect})
//         // }
//         next()
//     }
// })
//
// router.afterEach(() => {
//     NProgress.done() // finish progress bar
// })
