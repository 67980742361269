<template>
  <div class="main nav_box">
    <div class="wrap_in">
      <div class="top">
        <router-link to="/" class="fl"><img src="@/assets/images/nav/logo.png" alt=""></router-link>
        <div class="top_nav fc">
          <ul>
            <li>
              <router-link to="###"><i></i>产品
                <div class="nav_c1 project">
                  <ul>
                    <li v-for="item of product" :key="item.title">
                      <router-link :to="item.url">{{ item.title }}</router-link>
                    </li>
                  </ul>
                </div>
              </router-link>

              <!--                              <ul>-->
              <!--                                <li v-for="item of product" :key="item.title">-->
              <!--                                  <a :href="item.url">-->
              <!--                                    <img :style="'background:'+item.color" :src="imageUrl('index/advanceIcon/'+item.icon)">-->
              <!--                                    <span>{{ item.title }}</span>-->
              <!--                                  </a>-->
              <!--                                </li>-->
              <!--                              </ul>-->
            </li>
            <li>
              <router-link to="###"><i></i>解决方案
                <div class="nav_c1 solution">
                  <ul>
                    <li v-for="item of solution" :key="item.title">
                      <router-link :to="item.url">{{ item.title }}</router-link>
                      <!--                    <router-link :to="item.url">-->
                      <!--                      <img :style="{background:item.color,padding:0}" :src="imageUrl('nav/way/'+item.icon)">-->
                      <!--                      <span>{{ item.title }}</span>-->
                      <!--                    </router-link>-->
                    </li>
                  </ul>
                </div>
              </router-link>
            </li>
            <!--            <li><a href="###">内容中心</a></li>-->
            <li>
              <router-link to="/Case">客户案例</router-link>
            </li>
            <li>
              <router-link to="/customer">服务支持
                <div class="nav_c1 service">
                  <ul>
                    <li v-for="item of service" :key="item.title">
                      <router-link :to="item.url">{{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/news">新闻中心</router-link>
            </li>
            <!--            <li><a href="###">通知公告</a></li>-->
            <li>
              <router-link to="###"> 了解鹰能
                <div class="nav_c1 about">
                  <ul>
                    <li v-for="item of about" :key="item.title">
                      <router-link :to="item.url">{{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="fr nav_r">
          <div class="topr_r">
            <a @click="login" class="userLogin" v-if="isEmpty(userId)">用户登录</a>
            <avatar-drop-down v-else/>
            <!--            <div class="menu" @click="clickMenu">-->
            <!--              <em :class="{on:menuClick}">-->
            <!--                <i class="i1"></i>-->
            <!--                <i class="i2"></i>-->
            <!--                <i class="i3"></i>-->
            <!--              </em>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="consult_online">
      <ul>
        <li onclick="location.href='tencent://message/?uin=418329339&Site=在线QQ&amp;Menu=yes'">
          <img src="@/assets/images/nav/consult/r_01.png" alt="">
          <span>在线咨询</span>
        </li>
        <li>
          <img src="@/assets/images/nav/consult/r_02.png" alt="">
          <span>售前电话</span>
          <div class="phone_info">
            <p class="p1">咨询电话</p>
            <p class="p2">13518786721</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/nav/consult/r_03.png" alt="">
          <span>售后电话</span>
          <div class="phone_info">
            <p class="p1">咨询电话</p>
            <p class="p2">13518786721</p>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <login-modal ref="login"/>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import LoginModal from '../Login/LoginModal'
import {isEmpty} from "@/utils/util";
import AvatarDropDown from '../Login/AvatarDropdown'

export default {
  name: "Nav2",
  components: {
    LoginModal,
    AvatarDropDown
  },
  data() {
    return {
      // menuClick: false,
      menuClick: false,
      product: [{title: '调度指挥', icon: 'ddzh.png', color: '#0FBABA', url: '/product/dispatching'},
        {title: '标书制作', icon: 'bszz.png', color: '#597EF7', url: '/solution/bid'},
        {title: '项目管理', icon: 'xmgl.png', color: '#32A2FF', url: '/solution/project'},
        {title: '财务管理', icon: 'rsgl.png', color: '#FA8C16', url: '/product/finance'},
        {title: '风控管理', icon: 'fxgl.png', color: '#1890FF', url: '/product/risk'},
        {title: '安保培训', icon: 'absc.png', color: '#8DC70F', url: '/product/train'}
      ],

      solution: [
        // {title: '点对点调度指挥', icon: 'ddzh.png', color: '#FBA440'},
        // {title: '实时掌握项目实况', icon: 'ddqk.png', color: '#20DA9F',url:'/solution/project'},
        {title: '人事管理', icon: 'xchs.png', color: '#69D1C4', url: '/solution/hr'},
        // {title: '一键完成标书制作', icon: 'bszz.png', color: '#65C3FA',url:'/solution/bid'},
        {title: '人才盘点', icon: 'rcpd.png', color: '#50D261', url: '/solution/rcpd'},
        {title: '人才库', icon: 'rck.png', color: '#808AF9', url: '/solution/rck'}
      ],
      service: [
        {title: '应用下载', url: '/service/download'}
      ],
      about: [
        {title: '鹰能介绍', url: '/about'},
        {title: '发展历程', url: '/about/history'},
        {title: '联系鹰能', url: '/about/contact'},
        {title: '加入鹰能', url: '/about/join'}
      ],
    }
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  computed: {

    imageUrl() {
      return url => require('@/assets/images/' + url)
    },
    ...mapGetters(['userId'])
  },
  methods: {
    watchScroll() {
      // 滚动的距离
      const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      const offsetTop = document.querySelector(".nav_box").offsetHeight;
      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if (scrollTop >= offsetTop) {
        document.querySelector(".nav_box").classList.add("isTop");
      } else {
        document.querySelector(".nav_box").classList.remove("isTop");
      }
    },
    changePage(url) {
      window.location.href = url
    },
    isEmpty,
    ...mapMutations(['changeGlobleMask']),
    clickMenu() {
      this.menuClick = !this.menuClick
      this.changeGlobleMask()
    },
    login() {
      this.$refs.login.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './Nav.scss';
.isTop {
  position: sticky; // sticky
  top: 0;
  background-color: white;
  z-index: 99;
  width: 100vw;
  box-shadow: 6px 6px 5px #C0C0C0;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
</style>
