const getters = {
  // isMobile: state => state.app.isMobile,
  // lang: state => state.app.lang,
  // theme: state => state.app.theme,
  // color: state => state.app.color,
  globleMask: state =>state.globleMask,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.userName,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  userInfo: state => state.user.info,
  buttons: state => state.user.buttons,
  userId: state => state.user.userId,
  userName: state => state.user.userName,
  userAvatar: state => state.user.avatar,
  orgId: state => state.user.orgId,
  isSuperAdmin: state => state.user.userId === '1',
  isSecurityCompany: state => state.user.orgType === '01',
  isPropertyOwner: state => state.user.orgType === '02',
  isPublicSecurity: state => state.user.orgType === '03'
}
console.log(getters)
export default getters
