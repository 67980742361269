<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/product/risk/banner.jpg">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">风控管理</p>
          <p class="p3">风险·内控·合规一体化平台</p>
        </div>
      </div>
    </div>
    <div class="wrap_risk_01">
      <div class="wrap_in">
        <h2 class="title">风控管痛点</h2>
        <ul class="mobile">
          <li class="mw100">
            <h3>落地执行难<br>传统风控与实际业务关联性低</h3>
            <p>传统风控体系还是采用文档的方式，与合同、印章、相对方等实际业务场景关联性低，风控体系落地执行往往缺乏有效工具。</p>
          </li>
          <li class="mw100">
            <h3>动态监控难<br>缺乏系统集成，难以事前预警</h3>
            <p>传统方式下，因为各系统数据都是信息孤岛，难以获取和监控，所以只能靠人为发现问题后上报，难以实现风险的事先预警。</p>
          </li>
          <li class="mw100">
            <h3>体系监管难<br>管理过程难以沉淀</h3>
            <p>风控体系涉及全组织各层级，缺乏统一的风控平台，风险的识别、上报、处置、整改的闭环管理过程，进度、质量难以监管。</p>
          </li>
        </ul>
      </div>
      >
    </div>
    <div class="wrap_risk_02 wrap_in">
      <h2 class="title">风控管理目标</h2>
      <img src="@/assets/images/product/risk/home3.png">
    </div>
    <div class="wrap_risk_03">
      <div class="wrap_in">
        <h2 class="title">风控管理核心特色</h2>
        <div class="info mobile">
          <ul class="left mw100">
            <li>
              <h3>系统化 · 统一平台实现制度落地</h3>
              <p>包含风险、内控、合规在一个平台上就能实现从文档管理模式到系统化管理，实现制度落地</p>
            </li>
            <li>
              <h3>智能化 · 与业务结合，实现事前预警</h3>
              <p>与各异构系统集成，包括对接第三方资信平台构建各业务相关风险模型，智能化动态监控，发现风险征兆，实时多渠道风险预警</p>
            </li>
          </ul>
          <div class="center mw100">
            <img src="@/assets/images/product/risk/risk.png">
          </div>
          <ul class="mw100">
            <li>
              <h3>可视化 · 全过程闭环，可视化管理与展现</h3>
              <p>无论是风控体系上报，或是风险处置、整改过程，都能可视化监控，最终形成风控分析报告和报表</p>
            </li>
            <li>
              <h3>全程数字化 · 融入印控平台，防范印章风险</h3>
              <p>将印控管理体系融入风控平台，结合相关流程场景，审批授权后加盖电子印章。对所有印章使用，动态监控，用印过程完整记录，发现异常实时风险预警</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrap_risk_04 wrap_in">
      <h2 class="title">风控管理应用场景</h2>
      <ul class="mobile">
        <li class="mw100">
          <h3>合同履约风险</h3>
          <p>与合同管理平台及第三方资信平台集成，构建合同履约风险模型，智能识别相对方资信风险、合同逾期风险、合同收款风险、合同变更风险。系统自动预警，推送风控管理及合同管理人员进行应对。</p>
        </li>
        <li class="mw100">
          <h3>印章管理风险</h3>
          <p>融合印控管理平台，构建印章管理风险模型，智能识别用印行为风险、印章授权人员变更风险、数字证书有效期风险，系统自动预警，推送风控管理及印章负责人员进行处理。</p>
        </li>
        <li class="mw100">
          <h3>信用风险</h3>
          <p>与ERP系统集成，通过客户授信、评级以及应收账款回收率、逾期记录、催收记录，确定客户灰黑名单进行标注，限制授信，对风控部门、销售部门及客户定期进行风控绩效评价。</p>
        </li>
        <li class="mw100">
          <h3>内控制度流程协同</h3>
          <p>融合流程平台，将制度中要求的关键控制点融入到流程审批过程，以费控为例，报销过程自动审核是否冲销借款，是否关联事前申请，是否超预算，是否符合费用标准，实现内控与业务的结合。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// #F7F8FB
export default {
  name: "index",
  data () {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

h2.title {
  text-align: center;
  color: #20242b;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  padding: 60px;
}
.wrap_risk{
  &_01,&_02,&_03,&_04{
    h3 {
      color: #363f4e;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-size: 13px;
      line-height: 18px;
      color: #999999;
      margin-top: 10px;
    }
  }
}

.wrap_risk_01 {
  overflow: hidden;
  background: #F7F8FB;

  .wrap_in {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        background: #FFFFFF;
        width: 30%;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
}

.wrap_risk_02 {
  img {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
}

.wrap_risk_03 {
  background: #F7F8FB;
  padding-bottom: 80px;

  .info {
    display: flex;
    align-items: center;

    ul {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      li+li{
        margin-top: 20%;
      }
    }

    .center {
      width: 40%;

      img {
        width: 70%;
        display: block;
        margin: 0 auto;
      }
    }

    .left {
      text-align: right;
    }
  }
}

.wrap_risk_04 {
  padding-bottom: 80px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 24%;
      border: 1px solid #dddddd;
      padding: 30px;
      box-sizing: border-box;
    }
  }
}
</style>
