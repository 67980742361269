<template>
  <div class="main">
    <div class="img_wrap">
      <img src="@/assets/images/case/banner.jpg" alt="">
      <div class="fonts wrap_in">
        <h1>“客户案例</h1>
      </div>
    </div>
    <div class="wrap_in wrap_c_01">
      <h2>鹰能服务客户</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="item of [1,2,3]" :key="item">
          <a href="###" class="fy">
            <div class="top fx">
              <div class="circular fx">
                <img :src="require('@/assets/images/case/logo.jpg')" alt="">
              </div>
            </div>
            <span>云南大众安保服务集团有限公司</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-page',
          clickable: true,
          // type:'custom'
        },
      },
      serveTab: 0,
      serveData: [
        [
          {
            icon: 'zy_icon1.png',
            title: '蓝图规划',
            detail: '深入理解与洞察客户业务，分享企业实践基于客户需求快速梳理解决方案，建立成功基线',
          },
          {
            icon: 'zy_icon2.png',
            title: '业务实施',
            detail: '本地化现场服务+远程服务中心，快速实施分层分类对接，满足差异化客户需求',
          },
          {
            icon: 'zy_icon3.png',
            title: '能力建设',
            detail: '完善的客户培训体系，丰富的业务执行资源一对一远程个性化测评应用辅导',
          },
          {
            icon: 'zy_icon4.png',
            title: '业务实施',
            detail: '总结实施计划，确保客户以正确姿势开启测评之旅输出测评应用规范以及工具使用攻略',
          },
        ],
        [
          {
            icon: 'zy_icon01.png',
            title: '实施方法论',
            detail: '聚焦业务痛点，不是简单的实现将线下业务搬到线上引领成功实践，实现价值落地',
          },
          {
            icon: 'zy_icon02.png',
            title: '实施计划',
            detail: '首先通过基准实施，快速看到价值，然后进行迭代实施和价值实施',
          },
          {
            icon: 'zy_icon03.png',
            title: '实施价值',
            detail: '结合企业愿景，行业现状，发现客户面临的更多挑战，提供全方位的解决方案完善的培训体系，协助客户搭建内部运用体系',
          },
          {
            icon: 'zy_icon04.png',
            title: '实施目标',
            detail: '不只是一个结果，而是要真正达到预期目标落地业务流程规划与数据体系建设完成系统活跃度试运用，并定制提升计划',
          },
        ],
      ],
      memberServeTab: 0,
    }
  },
    methods: {
      clickServeTab(i)
      {
        this.serveTab = i
      }
    ,
      clickMemberServeTab(i)
      {
        this.memberServeTab = i
      }
    }
  }
</script>

<style lang="scss">
.main {
  .img_wrap {
    width: 100%;
    position: relative;

    img {
      width: 100%
    }

    .fonts {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      color: #FFFFFF;

      h1 {
        font-size: 34px;
        margin-bottom: 40px;
        font-weight: bold;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  .wrap_in {
    > h2 {
      margin: 100px 0 60px;
      font-size: 28px;
      text-align: center;
      position: relative;
      padding-bottom: 20px;

      &:after {
        content: "";
        width: 18px;
        height: 4px;
        background: $themeColor;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
      }
    }

  }

  .wrap_c_01 {
    margin-bottom: 90px;

    ul {
      display: flex;
      justify-content: space-between;

      li {
        border: 1px solid #E3E3E3;
        width: 32%;
        margin-right: 2%;
        box-sizing: border-box;
        cursor: pointer;
        float: left;
        padding-bottom: 70px;

        &:last-child {
          margin-right: 0;
        }

        > a {
          .top {
            width: 100%;
            height: 235px;

            .circular {
              width: 135px;
              height: 135px;
              border-radius: 50%;
              background: #F5F5F5;
            }
          }

          span {
            font-size: 18px;
            color: #111111;
            font-weight: bold;
          }
        }

        &:hover {
          border-color: $themeColor;

          img {
            animation: changeScale .4s;
          }
        }
      }
    }
  }

  .serve_tab {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 70px;
    border: 1px solid $themeColor;

    li {
      width: 50%;
      height: 100%;
      font-size: 24px;

      a {
        display: block;
        padding: 16px;
        text-align: center;
        line-height: 1.5;
        color: $themeColor;
      }

      &.active {
        background: $themeColor;

        a {
          color: #FFFFFF;
        }
      }
    }
  }

  .wrap_c_02 {
    background: #EDEDED;
    overflow: hidden;

    .wrap_ser {
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
          padding: 60px 80px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .image {
            width: 118px;
            height: 118px;
            background: #FFFFFF;
            border-radius: 50%;
            margin-right: 40px;
            position: relative;
            flex-shrink: 0;

            img {
              width: 54px;
            }

            &:after {
              content: "";
              width: 125px;
              height: 125px;
              border: 1px solid #FFFFFF;
              position: absolute;
              border-radius: 50%;
            }
          }

          .r {
            h3 {
              font-size: 24px;
              color: #222222;
              margin-bottom: 30px;
            }

            p {
              font-size: 18px;
              color: #888888;
              overflow: hidden;
            }
          }

          &:hover {
            background: $themeColor;

            img {
              animation: changeScale .4s;
            }

            .r {
              h3, p {
                color: #FFFFFF
              }
            }
          }
        }
      }
    }
  }

  .wrap_c_03 {
    padding-bottom: 90px;
    background: #F8F9FB;

    .wrap_ser {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          width: 32%;
          margin-right: 2%;
          border-top: 5px solid $themeColor;
          padding: 55px;
          box-sizing: border-box;
          background: #FFFFFF;
          height: 258px;

          h3 {
            font-size: 24px;
            color: #222222;
            margin-bottom: 30px;
          }

          p {
            font-size: 18px;
            color: #888888;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            margin-top: -2px;
          }
        }
      }
    }
  }

  .wrap_c_04 {
    .swiper {
      max-width: 1320px;
      overflow: hidden;
    }

    .swiper-slide {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .fl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-size: 24px;
          margin-bottom: 55px;
        }

        label {
          display: flex;
          align-items: center;
          height: 45px;

          i {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $themeColor;
            margin-right: 5px;
          }

          span {
            color: $themeColor;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            color: #888888;
          }
        }
      }

      .fr {
        display: flex;

        .item {
          width: 250px;
          height: 349px;
          font-size: 22px;
          color: #FFFFFF;
          display: flex;
          align-content: center;
          justify-content: center;
          line-height: 2;
          flex-wrap: wrap;
          margin-left: 20px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: cover;

          span {
            margin-top: 30px;
            color: #FFFFFF;
          }

          &:hover {
            img {
              animation: changeScale .4s;
            }
          }
        }
      }
    }

    .swiper-page {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 90px 0;
      overflow: hidden;

      .swiper-pagination-bullet {
        margin: 0 3px;

        &.swiper-pagination-bullet-active {
          width: 26px !important;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
