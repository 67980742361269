<template>
  <div class="main">
    <div class="banner">
      <img src="@/assets/images/solution/hr/banner.jpg" alt="">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">人事管理解决方案</p>
          <p class="p2">全面数字化，智造高绩效员工</p>
          <p class="p3">生态化招聘、数字化员工、知识化赋能</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_pro_01">
      <h2 class="title">数字化时代，如何解决人事管理5大痛点</h2>
      <p class="p_info">数字化时代，人力资源管理，面临着战略重性不断提升与员工管理、赋能场景更趋复杂的矛盾，流程长、环节多，以下4个痛点尤为突出：</p>
      <ul class="mobile">
        <li class="mobile100 mfx" v-for="(item,i) in proArr1" :key="item.title">
          <img class="mw40" :src="imgUrl(`td0${i+1}.png`)">
          <div style="text-align: center">
            <h3>{{ item.title }}</h3>
            <p>{{ item.info }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap_pro_02">
      <div class="wrap_in">
        <h2 class="title">全面数字化的人事管理，服务、赋能员工更高效</h2>
        <p class="p_info">
          基于众多500强企业管理最佳实践，打造全面数字化的人事管理平台，支持组织、招聘、员工、考勤、绩效、薪酬、培训等全程在线管理，全面赋能员工，打造高绩效组织。</p>
        <img class="mw100" src="@/assets/images/solution/hr/jiagou.png"/>
      </div>
    </div>
    <div class="wrap_pro_03 wrap_in">
      <h2 class="title">人事管理平台应用亮点</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="(item,i) in proArr2" :key="i">
          <h3>{{ item.title }}</h3>
          <p>{{ item.info }}</p>
        </li>
      </ul>
    </div>
    <div class="wrap_pro_04">
      <div class="wrap_in">
        <h2 class="title">人事管理平台应用价值</h2>
        <p class="p_info">数字化的人事管理平台，全面赋能人才“选、育、用、留”，支撑企业深掘人力资源价值，助力企业更好发展。</p>
        <ul class="mobile">
          <li class="mw100 mfx" v-for="(item,i) in proArr3" :key="i">
            <el-image class="img" :src="imgUrl(`jz0${i+1}.png`)" fit="cover"></el-image>
            <div>
              <h3 class="ellipsis">{{ item.title }}</h3>
              <p>{{ item.info }}</p>
            </div>

          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",

  data () {
    return {
      proArr1: [
        {
          title: '员工招聘难',
          info: '需求、岗位、应聘等管理不同步，招聘效率低，业务部门怨言多'
        },
        {
          title: '员工服务慢',
          info: '新人入职、岗位调整、员工离职等流程慢，服务员工满意度较低'
        },
        {
          title: '绩效考评累',
          info: '绩效指标管理混乱，纸质考评过程繁琐，没有分析支撑绩效改善'
        },
        {
          title: '员工赋能弱',
          info: '企业培训手段陈旧，员工自主学习热情激发不够，个人成长缓慢'
        },
        {
          title: '数据统计烦',
          info: '人事数据更新慢，手工统计低效，多维度分析缺失，管理支撑弱'
        },
      ],
      proArr2: [
        {
          title: '生态化招聘管理，更高效',
          info: '面试者扫码加入生态组织，与企业随时随地沟通；拉通招聘申请、通知面试、在线应聘、入职、合同签署等流程；让人才“选、面、签”更简单'
        },
        {
          title: '数字化员工管理，更便捷',
          info: '构建员工职业全周期数字化档案，结合入职、调岗、调薪、社保办理等流程的打通，让数据自动传递，省略重复发录入，方便HR工作，提升服务员工效率'
        },
        {
          title: '智能化考勤管理，人性化',
          info: '提供灵活排班、加班等管理，让人力资源配置更科学；支持自动打卡、异地打卡等，考勤异常在线快速处理、考勤数据一键生成，员工、HR都满意'
        },
        {
          title: '在线化绩效管理，更科学',
          info: '通过绩效指标库搭建、考评组设置、考核发起、绩效目标制定、考核评分、绩效报表分析等全周期在线管理，让绩效管理科学、便捷，激活员工潜能'
        },
        {
          title: '自定义薪酬管理，更灵活',
          info: '通过设置薪资包及薪资档案、员工定薪、社保、公积金方案制定、新建发薪活动、发薪审核、薪酬发放等全程管理，让工资、薪酬计算准确，高效发放'
        },
        {
          title: '自助门户，让员工更满意',
          info: '构建员工自助门户，罗列出与“我”相关的工资、福利、考核、借款、资产、假期、收入证明等快捷入口，不用麻烦相关人员，一键获取，省力更省心'
        },
        {
          title: '人事报表，让决策更科学',
          info: '提供多维度的人事数据统计报表与分析，让企业人力资源状态一目了然，HR主管、企业领导，随时随地掌控人力资本情况，作出更科学的决策'
        },
        {
          title: '角色化员工赋能，成长快',
          info: '根据新人、研发、销售等不同岗位，打造角色化的赋能包，将多类学习地图与培训课程结合，让员工在闯关模式下，不断提升自身技能与业务水平'
        }
      ],
      proArr3: [
        {
          title: '提升员工的满意度',
          info: '全程在线，提效人才“选育用留”，员工满意度更高'
        },
        {
          title: '提升HR工作效率',
          info: '多系统集成，拉通流程，让HR工作更简单、更高效'
        },
        {
          title: '提效领导洞察决策',
          info: '人事门户数据全、待办快，方便洞察决策、处理工作'
        },
      ]
    }
  },
  computed: {
    imgUrl () {
      return imgName => require(`@/assets/images/solution/hr/${imgName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

h2.title {
  text-align: center;
  color: #20242b;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
}

p.p_info {
  margin: 40px auto;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737c8b;
}

.wrap_pro_01 {
  margin: 100px 0;
  overflow: hidden;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: 16%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1%;

      img {
        width: 50%;
      }

      h3 {
        color: #363f4e;
        font-size: 20px;
        line-height: 24px;
        margin: 10px 0;
      }

      p {
        color: #737c8b;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.wrap_pro_02 {
  background: #F7F8FB;
  padding: 100px 0;

  p {
    width: 80%
  }

  img {
    display: block;
    width: 80%;
    margin: 60px auto 0;
  }
}

.wrap_pro_03 {
  margin: 100px 0 0;
  overflow: hidden;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;

    li {
      width: 24%;
      margin-bottom: 40px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #DDDDDD;

      h3 {
        color: #363f4e;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      p {
        color: #737c8b;
        font-size: 16px;
        line-height: 24px;
      }

      .image_preview {
        width: 100%;
        margin-top: 30px;
        border: 1px solid #F3F3F3;
        padding: 1%;
      }
    }
  }
}

.wrap_pro_04 {
  background: #F7F8FB;
  padding: 100px 0;

  ul {
    display: flex;
    justify-content: center;

    li {
      width: 20%;
      margin: 0 5%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {
        width: 50%;
      }

      h3 {
        height: 70px;
        color: #20242b;
        font-size: 20px;
        line-height: 70px;
        text-align: center;
      }

      p {
        text-align: center;
        color: #737c8b;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
</style>
