<template>
  <div class="main">
    <div class="wrap">
      <h2>立刻开始使用</h2>
      <el-tabs v-model="types[0].appType" style="padding: 0px 64px">
        <div v-for="item of types" :key="item.appType">
          <el-tab-pane :label="item.title" :name="item.appType">
            <ul>
              <li span="12" v-for="(it, i) in data" :key="i">
                <img src="@/assets/images/about/android-icon.svg" alt="">
                <h3>{{ it.platform+'客户端' }}</h3>
                <a :href="`https://file.ynab.com.cn/file/download?id=`+it.fileId">立即下载</a>
<!--                <a href="https://file.ynab.com.cn/file/download?id=197a9102d39f4180ba83b6a7521ee7ad">立即下载</a>-->
              </li>
            </ul>
          </el-tab-pane>
        </div>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import {getSysAppLatests} from "@/api/system/sysApp";

export default {
  name: "index",
  data() {
    return {
      data: [{platform: 'android', title: '鹰能安保'}],
      types: [{appType: 'main-app', title: '鹰能安保'}, {appType: 'line-worker-app', title: '安保一线'}],
      download: {
        main: [
          {
            parameter: {appType: 'main-app', platform: 'Android'}
          },
          {
            parameter: {appType: 'main-app', platform: 'IOS'}
          }
        ],
        lineWorker: [
          {parameter: {appType: 'line-worker-app', platform: 'Android'}}
        ]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getAppList()
    },
    getAppList() {
      getSysAppLatests().then(res => {
        this.data = res
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
