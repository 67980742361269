<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

import {mapGetters} from 'vuex'


export default {
  components: {},
  provide() {

  },
  data () {
    return {
      isShowNavFooter: true
    }
  },
  computed: {
  },
  created () {
    console.log(1)
    let routePath = this.$route.path
    if (routePath.trim() === '/' || routePath.trim() === '') {
      this.isShowNavFooter = false
    }
  }
}
</script>

<style lang="scss">
@import "./assets/css/reset.css";
@import "./assets/css/iconfont.css";
@import "./assets/css/common.scss";
@import "./assets/css/style.scss";

</style>
