<template>
  <div class="main">

    <div class="banner">
      <img src="@/assets/images/solution/rcpd/banner.jpg" alt="">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">人才盘点方案</p>
          <p class="p3">提供全面的人才评估工具和盘点解决方<br>帮助企业落地人才盘点，盘点会服务和盘点结果应用</p>
        </div>
      </div>
    </div>
    <div class="center_nav">
      <ul>
        <!--        <li><a href="">盘点时机</a></li>-->
        <li><a href="#one">盘点方案</a></li>
        <li><a href="#two">应用方向</a></li>
        <li><a href="#three">企业价值</a></li>
        <!--        <li><a href="">客户案例</a></li>-->
        <!--        <li><a href="">鹰能优势</a></li>-->
        <!--        <li><a href="">资讯方案</a></li>-->
      </ul>
    </div>
    <div class="wrap_in">
      <div class="wrap_ct_01 box_item" style="display: none">
        <h2>什么样的企业需要人才盘点</h2>
        <div class="content">
          <ul>
            <li>
              <div class="box">
                <h3>快速发展阶段</h3>
                <p><b>业务挑战：</b>企业快速发展，人才断档，人才供应不足</p>
                <p><b>盘点目标：</b>发现高潜、加速培养、搭建人才梯队</p>
                <span>人才盘点方案助力人才补给与换代</span>
              </div>
            </li>
            <li>
              <div class="box">
                <h3>转型变革阶段</h3>
                <p><b>业务挑战：</b>进入新市场，多元化发展，缺乏变革性人才</p>
                <p><b>盘点目标：</b>发展变革人才、现有团队重组安置</p>
                <span>人才盘点方法助力人才升级与迁移</span>
              </div>
            </li>
            <li>
              <div class="box">
                <h3>关键人员调整</h3>
                <p><b>业务挑战：</b>快速摸底团队现状，实现有效管理，发现优秀人才</p>
                <p><b>盘点目标：</b>人才摸底，发现高潜，加速培养；搭建梯队</p>
                <span>人才盘点系统助力人才摸底和调整</span>
              </div>
            </li>
            <li>
              <div class="box">
                <h3>上市筹备阶段</h3>
                <p><b>业务挑战：</b>建立流程化的人才管理体系、发现高潜人才针对性培养</p>
                <p><b>盘点目标：</b>发现高潜，针对性培养；人才地图，核心员工激励保留；流程化、体系化</p>
                <span>人才盘点工具助力人才体系完善</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrap_ct_02 box_item" id="one">
        <h2>鹰能人才盘点SAFE解决方案</h2>
        <div class="content">
          <ul>
            <li :class="{on:solveWayId===way.id}" @click.stop="clickSolveWay(way.id)" v-for="way of solveWayData"
                :key="way.id">
              <a href="javascript:;">
                <img :src="solveWayUrl(way.icon,way.id)" :alt="way.title">
                <span>{{ way.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrap_ct_03 box_item" id="two">
        <h2>应用方向</h2>
        <div class="content">
          <ul class="mobile">
            <li class="mobile100" v-for="item of usePosition" :key="item.title">
              <div class="box">
                <img :src="usePositionUrl(item.icon)" :alt="item.title">
                <h3>{{ item.title }}</h3>
                <p>{{ item.details }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrap_ct_04 box_item" id="three">
        <h2>人才盘点的价值</h2>
        <div class="content">
          <ul class="mobile">
            <li class="mobile100" v-for="item of manValue" :key="item.title">
              <div class="box">
                <h3>{{ item.title }}</h3>
                <p>{{ item.details }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrap_ct_05 box_item" style="display: none">
        <h2>鹰能优势</h2>
        <div class="content">
          <ul>
            <li v-for="item of advantageData" :key="item.title">
              <div class="box">
                <img :src="usePositionUrl(item.icon)" alt="">
                <h3>{{ item.title }}</h3>
                <p>{{ item.details }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatZero } from "../../../utils/function";

export default {
  name: "Rcpd",
  data () {
    return {
      solveWayData: [
        {
          id: 1,
          icon: 'learn-cp',
          title: '盘点梳理'
        },
        {
          id: 2,
          icon: 'learn-cp',
          title: '人才标准'
        },
        {
          id: 3,
          icon: 'learn-cp',
          title: '全面评估'
        },
        {
          id: 4,
          icon: 'learn-cp',
          title: '校准反馈'
        },
        {
          id: 5,
          icon: 'learn-cp',
          title: '形成地图'
        },
        {
          id: 6,
          icon: 'learn-cp',
          title: '结果应用'
        },
      ],
      solveWayId: 1,
      usePosition: [
        {
          icon: 'learn-u188',
          title: '培训发展',
          details: '谁应该得到培训激励，培训什么，培训的方向是什么',
        },
        {
          icon: 'learn-u187',
          title: '激励保留',
          details: '谁值得激励，用什么方式激励',
        },
        {
          icon: 'learn-u186',
          title: '梯队建设',
          details: '谁更胜任当前岗位，谁应该调岗',
        },
        {
          icon: 'learn-u185',
          title: '挖掘高潜',
          details: '谁更有潜力，谁值得投入',
        },
      ],
      manValue: [
        {
          title: '对企业的价值',
          details: '提高用工效率、建立统一的人才标准，人才可见、人岗匹配、人才透明流动'
        },
        {
          title: '对管理者的价值',
          details: '了解员工、发展员工、提升自我、团队业绩提升'
        },
        {
          title: '对员工的价值',
          details: '获得更针对性的培养，加速能力提升；能看到更多、更合适的职业发展路径'
        },
      ],
      advantageData: [
        {
          icon: 'learn-u278',
          title: '5000+',
          details: '标杆企业成功实践'
        },
        {
          icon: 'learn-u280',
          title: '16年',
          details: '专业技术积累'
        },
        {
          icon: 'learn-u282',
          title: '安全保障',
          details: '安全稳定、高效的运维保障体系'
        },
        {
          icon: 'learn-u283',
          title: '客户成功',
          details: '专业客户成功服务为HR赋能'
        },
        {
          icon: 'learn-u284',
          title: '科学决策',
          details: '基于大数据分析的科学人才决策'
        },
        {
          icon: 'learn-u285',
          title: '快速迭代',
          details: 'SAAS模式快速迭代'
        },
      ]
    }
  },
  computed: {
    solveWayUrl () {
      return (icon, number) => {
        const solveWayId = this.solveWayId;
        const iconNumber = solveWayId === number ? number * 2 : number * 2 - 1;
        const parseNumber = formatZero(iconNumber, 2)
        return require(`@/assets/images/solution/rcpd/${icon}${parseNumber}.png`)
      }
    },
    usePositionUrl () {
      return icon => require(`@/assets/images/solution/rcpd/${icon}.png`)
    }
  },
  methods: {
    clickSolveWay (id) {
      this.solveWayId = id
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      .wrap_in {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #FFFFFF;

        .p1 {
          font-size: 36px;
          margin-bottom: 20px;
        }

        .p2 {
          font-size: 46px;
          margin-bottom: 30px;
        }

        .p3 {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  .center_nav {
    width: 100%;
    background: #EFF0F2;

    > ul {
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        width: 13.8%;
        height: 59px;
        line-height: 59px;
        text-align: center;
        font-size: 14px;
        color: #0e1021;
        border-bottom: 1px solid #EFF0F2;

        a {
          display: block;
          width: 100%;
          height: 100%
        }

        &:hover {
          border-bottom-color: $themeColor;
          color: $themeColor;
        }
      }
    }
  }

  .box_item {
    h2 {
      margin: 30px 0;
      font-size: 28px;
      color: #191140;
      text-align: center;
      letter-spacing: 1px;
      font-weight: inherit;
    }
  }

  .wrap_ct_01 {
    .content {
      padding-bottom: 10px;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          width: 25%;
          height: 270px;
          padding: 0 15px;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;

          &:hover {
            margin-top: -5px
          }

          .box {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            background: #F2F2F2;
            overflow: hidden;

            h3 {
              width: 150px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              font-size: 18px;
              font-weight: 300;
              border-radius: 20px;
              background: #1AA5DD;
              color: #FFFFFF;
              margin: auto;
              margin-top: 40px;
            }

            p {
              font-size: 14px;
              margin: 20px;
              color: #333333;
            }

            span {
              width: calc(100% - 30px);
              height: 45px;
              line-height: 45px;
              text-align: center;
              background: $themeColor;
              position: absolute;
              left: 15px;
              bottom: 0;
              color: #FFFFFF;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .wrap_ct_02 {
    padding-bottom: 50px;

    .content {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        li {
          width: 16.5%;
          box-sizing: border-box;
          position: relative;

          &.on {
            span {
              color: $themeColor;
              border-bottom-color: $themeColor;

              &:after {
                background: $themeColor;
              }
            }
          }

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            top: 44%;
            border-bottom: 1px dashed #000;
          }

          > a {
            display: block;
            position: relative;

            &:after, &:before {
              content: '';
              display: block;
              position: absolute;
              top: 44%;
              margin-top: -4px;
              right: 2px;
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-left: 10px solid #000;
              border-bottom: 5px solid transparent;
            }

            &:before {
              left: 2px;
            }

            img {
              position: relative;
              display: block;
              width: 80%;
              margin: auto;
              z-index: 1;
            }

            span {
              font-size: 14px;
              font-weight: 500;
              height: 34px;
              line-height: 20px;
              display: flex;
              align-items: center;
              text-align: center;
              flex-direction: column;

              &:after {
                display: block;
                content: "";
                bottom: 0;
                height: 2px;
                width: 50px;
                margin-top: 2px;
                background: #FFFFFF;
              }
            }
          }
        }

        li:first-child {
          &:after {
            width: 50%;
            left: inherit;
            right: 0;
          }

          a:before {
            display: none;
          }
        }

        li:last-child {
          &:after {
            width: 50%;
            right: 0;
          }

          a:after {
            display: none;
          }
        }
      }
    }
  }

  .wrap_ct_03 {
    .content {
      overflow: hidden;

      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        li {
          width: 25%;
          height: 294px;

          &:hover {
            margin-top: -5px;
          }

          .box {
            margin: 0 30px;
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #F2F2F2;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background: #FFFFFF;
            }

            h3 {
              font-size: 22px;
              margin-top: 20px;
            }

            p {
              font-size: 14px;
              padding: 30px 0;
            }
          }
        }
      }
    }
  }

  .wrap_ct_04 {
    padding-bottom: 50px;

    .content {
      ul {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        li {
          width: 30%;
          height: 210px;
          border: 1px solid #e2e2e2;
          box-shadow: 8px 0 18px rgb(0 0 0 / 6%);
          padding: 20px;
          box-sizing: border-box;

          &:hover {
            margin-top: -5px;
            box-shadow: none;
          }

          h3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            font-size: 18px;
            line-height: 1.5;
            justify-content: space-between;
            height: 34px;

            &:after {
              content: "";
              display: block;
              width: 60px;
              height: 3px;
              background: $themeColor;
            }
          }

          p {
            width: 100%;
            display: block;
            overflow: hidden;
            font-size: 14px;
            color: #222;
            line-height: 1.5;
            margin-top: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
