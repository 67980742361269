<template>
  <div class="main mp2">
    <div class="banner">
      <img src="@/assets/images/product/dispatching/banner.jpg" alt="">
      <div class="wrap wrap_dp_01 mobile">
        <div class="left mobile100 pr0">
          <span>鹰能调度指挥</span>
          <h1 class="ellipsis">智能高效的调度指挥管理</h1>
          <p>通过场景化流程，根据权限和事件紧急程度，实现群组内一对一对讲，群组实时广播对讲(多人会议模式)，紧急呼叫直达顶层。</p>
          <div class="btn_wrap">
            <a href="javascript:;" class="active">免费试用产品</a>
            <a href="javascript:;">在线咨询</a>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap wrap_dp_02">
      <h2>业务价值</h2>
      <div class="content">
        <ul class="mobile mh0">
          <li class="mobile100">
            <a href="javascript:;">
              <h3>提升人才质量</h3>
              <p class="p1">从人才标准到人才甄选，科技引领高质量招聘</p>
              <p>与北森人才测评/考试天然融合</p>
              <p>基于能力测评与AI智能匹配</p>
              <p>人才识别失误减少70%</p>
            </a>
          </li>
          <li class="mobile100">
            <a href="javascript:;" class="a1">
              <h3>提升招聘效率</h3>
              <p class="p1">全流程的数字化招聘，减少HR工作量</p>
              <p>流程自动流转，招聘周期缩短50%</p>
              <p>简历筛选效率提升230%</p>
              <p>与候选人沟通时间减少60%</p>
            </a>
            <a href="javascript:;" class="a2">
              <h3>提升人才质量</h3>
              <p class="p1">从人才标准到人才甄选，科技引领高质量招聘</p>
              <p>与北森人才测评/考试天然融合</p>
              <p>基于能力测评与AI智能匹配</p>
              <p>人才识别失误减少70%</p>
            </a>
          </li>
          <li class="mobile100">
            <a href="javascript:;" class="a1">
              <h3>提升招聘效率</h3>
              <p class="p1">全流程的数字化招聘，减少HR工作量</p>
              <p>流程自动流转，招聘周期缩短50%</p>
              <p>简历筛选效率提升230%</p>
              <p>与候选人沟通时间减少60%</p>
            </a>
            <a href="javascript:;" class="a2">
              <h3>提升人才质量</h3>
              <p class="p1">从人才标准到人才甄选，科技引领高质量招聘</p>
              <p>与北森人才测评/考试天然融合</p>
              <p>基于能力测评与AI智能匹配</p>
              <p>人才识别失误减少70%</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap wrap_dp_03">
      <h2>核心功能</h2>
      <div class="content">
        <ul>
          <li class="active"><a href="javascript:;">整合运营招聘渠道</a></li>
          <li><a href="javascript:;">智能管理招聘过程</a></li>
          <li><a href="javascript:;">数据驱动业务决策</a></li>
          <li><a href="javascript:;">多端/多角色招聘协同</a></li>
        </ul>
        <div class="info mobile">
          <img src="" alt="" class="mobile100 mr0">
          <div class="fonts_wrap mobile100">
            <h3>多端/多角色招聘协同</h3>
            <p><span>多端应用</span>系统支持PC端/移动端多端应用，随时随地接入，便捷处理招聘业务</p>
            <p><span>灵活集成</span>可灵活集成到企业自有eHR、OA系统，对接到微信、企业微信、钉钉、飞书等平台</p>
            <p><span>多角色协同</span>支持HR、面试官、候选人、猎头、业务经理、内部员工等多角色在线沟通，高效协同</p>
            <a href="javascript:;">立即预约演示</a>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap wrap_dp_04">
      <h2>产品优势</h2>
      <div class="content mobile">
        <div class="left mobile100 mr60 mr0">
          <h3>一体化的整合招聘</h3>
          <div class="info mobile100">
            <a v-for="item of [0,1,2]" :key="item" href="javascript:;" @mouseover="showEwm(0,item,'1')" @mouseout="showEwm(1,item,'1')">
              <h3><span>招聘测评一体</span><i class="iconfont icon-next"></i></h3>
              <p :class="{active:index1===item}">与北森测评/考试深度融合，承接人才标准和关键岗位能力模型，基于能力评测与面试无缝衔接，实现高质量的人才甄选</p>
            </a>
          </div>
        </div>
        <img src="" class="mobile100">
      </div>
    </div>
    <div class="wrap wrap_dp_04">
      <div class="content mobile">
        <img src="" class="mobile100 mr60 mr0">
        <div class="left mobile100">
          <h3>智能化的场景应用</h3>
          <div class="info mobile100">
            <a v-for="item of cjyy" :key="item.id" href="javascript:;" @mouseover="showEwm(0,item.id,'2')" @mouseout="showEwm(1,item,'2')">
              <h3><span>{{ item.title}}</span><i class="iconfont icon-next"></i></h3>
              <p :class="{active:index2===item.id}">{{item.cont}}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data () {
    return {
      index1:0,
      index2:1,
      cjyy:[
        {
          id:1,
          title:'简历解析',
          cont:'支持招聘网站简历/PPT/PDF/图片等多格式简历智能解析，高效精准，智能查重，极大减少HR简历录入工作量'
        },
        {
          id:2,
          title:'AI视频面试（闪面）',
          cont:'基于语音语义解析/人岗匹配等AI技术，结合硬性条件和软性素质评测，打破面试时空限制，AI辅助多维度分析候选人'
        },
        {
          id:3,
          title:'智能筛选',
          cont:'AI自动打标签（教育背景、工作经验、技能特长等），岗位画像与人才画像智能匹配，高效辅助简历筛选'
        },
        {
          id:4,
          title:'人才智能激活',
          cont:'基于人岗匹配模型，AI智能推荐人才库匹配简历，外呼机器人自动激活候选人，实现人才库的闭环盘活'
        },
        {
          id:5,
          title:'应聘咨询机器人',
          cont:'在招聘官网/微官网中，应聘咨询机器人7×24小时应答候选人咨询，自动引导报名/投递，释放HR沟通压力'
        },
      ]
    }
  },
  created () {
    // console.log(components);
  },
  methods: {
    showEwm (i,val,index) {
      if(!i){
        this[i+index] = val
      }
      console.log(this.index1)
      console.log(this.index2)
    }
  }
}
</script>

<style lang="scss" scoped>

.wrap {
  max-width: 1180px;
  margin: 0 auto;
  padding-bottom: 56px;

  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
  }
}
.mr60{
  margin-right: 60px;
}
//.mr0{
//  margin-right: 0!important;
//}
.banner{
  overflow: hidden;
  position: relative;
  >img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    //height: 100%;
  }
}
.wrap_dp_01 {
  min-height: 470px;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  overflow: hidden;
  display: flex;

  .left {
    float: left;
    min-height: 300px;
    width: calc(100% - 640px);
    padding-right: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    span {
      font-size: 18px;
      margin-bottom: 14px;
      line-height: 22px;
    }

    h1 {
      color: #000;
      font-size: 48px;
      line-height: 60px;
    }

    p {
      font-size: 16px;
      color: #575b62;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 40px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .btn_wrap {
      padding-top: 10px;
      display: flex;
      align-items: center;

      a {
        display: inline-block;
        height: 48px;
        padding: 0 30px;
        color: $themeColor;
        border: 1px solid $themeColor;
        font-size: 16px;
        margin-right: 20px;
        text-align: center;
        line-height: 48px;
        border-radius: 5px;

        &.active {
          background: $themeColor;
          color: #FFFFFF;
        }
      }
    }
  }

  .right {
    float: right;
    width: 640px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      min-height: 300px;
    }
  }
}

.wrap_dp_02 {
  .content {
    ul {
      height: 252px;
      display: flex;
      align-items: center;
      border: 1px solid #dde4f0;
      box-shadow: 0 5px 30px 0 rgb(239 239 239 / 75%);
      box-sizing: border-box;
      overflow: hidden;
      border-radius: 5px;

      li {
        width: 33.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        a {
          box-sizing: border-box;
          display: block;
          padding: 30px;
          border-right: 1px solid #dde4f0;
          background: #F2FAFF;

          h3 {
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            color: #000000;
            line-height: 30px;
            box-sizing: border-box;
          }

          p {
            font-size: 16px;
            color: #666666;
            line-height: 24px;
            margin: 15px 0;
          }

          .p1 {
            margin-bottom: 30px;
          }

          &.a2, &.a1 {
            height: 50%;

            &.a2 {
              border-top: 1px solid #dde4f0;
            }
          }

          &:hover {
            height: 100%;
            transition: height .4s;
          }
        }

        &:last-child a {
          border-right: 0;
        }
      }
    }
  }
}

.wrap_dp_03 {
  .content {
    > ul {
      display: flex;
      align-items: center;

      li {
        flex: 1;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 2px solid #FFFFFF;

        a {
          font-size: 16px;
          font-weight: bold;
        }

        &:hover a {
          color: $themeColor;
        }

        &.active {
          border-bottom-color: $themeColor;
        }

        a {
          display: block
        }
      }
    }

    .info {
      padding-top: 60px;
      display: flex;
      align-items: center;

      > img {
        width: 756px;
        margin-right: 60px;
        background: #F1F3F4;
        min-height: 300px;
      }

      .fonts_wrap {
        h3 {
          font-size: 36px;
          color: #000000;
          letter-spacing: 0;
          line-height: 48px;
          margin-bottom: 30px;
        }

        p {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-bottom: 20px;

          span {
            font-size: 16px;
            color: #000000;
            display: block;
          }
        }

        a {
          height: 50px;
          line-height: 50px;
          padding: 0 30px;
          background: $themeColor;
          color: #FFFFFF;
          font-size: 16px;
          vertical-align: middle;
          text-align: center;
          border-radius: 4px;
          float: left;
          margin-top: 10px;
          margin-right: 20px;

          &:hover {
            background: #2c97e8;
          }
        }
      }
    }
  }
}

.wrap_dp_04 {
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {

      > h3 {
        font-size: 36px;
        color: #000000;
        margin-bottom: 20px;
      }

      .info {
        a {
          display: block;
          padding: 20px 0;
          border-bottom: 1px solid #dde4f0;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            //p{
            //  display: block;
            //}
            i {
              transform: rotate(-90deg)
            }

            h3 span {
              color: $themeColor
            }
          }

          h3 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            span {
              font-size: 16px;
            }

            i {
              font-size: 12px;
              color: #A5A5A5;
              transform: rotate(90deg);
            }
          }

          p {
            display: none;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: 10px;

            &.active {
              display: block;
              transition: display 4s;
            }
          }
        }
      }
    }

    > img {
      width: 708px;
      min-height: 400px;
      background: #F1F3F4;
    }
  }
}
</style>
