<template>
  <div class="main mp2">
<!--    <div class="banner">
      <img src="@/assets/images/product/dispatching/banner.jpg" alt="">
      <div class="wrap wrap_dp_01 mobile">
        <div class="left mobile100 pr0">
          <span>鹰能调度指挥</span>
          <h1 class="ellipsis">智能高效的调度指挥管理</h1>
          <p>通过场景化流程，根据权限和事件紧急程度，实现群组内一对一对讲，群组实时广播对讲(多人会议模式)，紧急呼叫直达顶层。</p>
          <div class="btn_wrap">
            <a href="javascript:;" class="active">免费试用产品</a>
            <a href="javascript:;">在线咨询</a>
          </div>
        </div>
      </div>
    </div>-->
    <div class="banner">
      <img src="@/assets/images/product/dispatching/banner1.jpg">
      <div class="box">
        <div class="wrap_in">
          <p class="p1">智能高效的调度指挥管理</p>
          <p class="p3">通过场景化流程，根据权限和事件紧急程度，实现群组内一对一对讲<br>群组实时广播对讲(多人会议模式)，紧急呼叫直达顶层。</p>
        </div>
      </div>
    </div>
    <div class="wrap_in wrap_dp_02">
      <h2>产品特点</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="item in productTrait" :key="item.icon">
          <a href="javascript:;" class="mfx">
            <div class="img_wrap">
              <img :src="require(`@/assets/images/product/dispatching/${item.icon}`)">
            </div>
            <div class="font_wrap">
              <h3>{{ item.title }}</h3>
              <p class="ellipsis4">{{ item.info }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="wrap_in wrap_dp_02">
      <h2>产品功能</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="item in productPurpose" :key="item.icon">
          <a href="javascript:;" class="mfx">
            <div class="img_wrap">
              <img :src="require(`@/assets/images/product/dispatching/${item.icon}`)">
            </div>
            <div class="font_wrap">
              <h3>{{ item.title }}</h3>
              <p class="ellipsis4">{{ item.info }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="wrap_in wrap_dp_02">
      <h2>应用场景</h2>
      <ul class="mobile">
        <li class="mobile100" v-for="item in productDemo" :key="item.icon">
          <a href="javascript:;" class="mfx">
            <div class="img_wrap">
              <img :src="require(`@/assets/images/product/dispatching/${item.icon}`)">
            </div>
            <div class="font_wrap">
              <h3>{{ item.title }}</h3>
              <p class="ellipsis4">{{ item.info }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data () {
    return {
      productTrait:[
        {
          icon:'01_01.png',
          title:'可视化安保实时调度指挥',
          info:'通过动态分组、人员类别、装备类别等实现实时在线指挥，包括文字及音视频指挥信息下达，自动化调度指令等。指挥平台可实时跟踪查看调度指令的执行进度。'
        },
        {
          icon:'01_02.png',
          title: '高效直观的融合指控',
          info:'指挥人员可在鹰能安保调度平台上实现语音调度、视频调度、GIS调度、视频会商、信息发布、智能预警等各类调度功能的操作，实现对各子系统的集成管理、一体化控制、可视化展现及便捷性操作，达到高效直观的融合指控、统一调度。'
        },
        {
          icon:'01_03.png',
          title: '一体化管理',
          info:'实现各类风险识别、评估、监控、预警、处置的全过程动态管理。统一规划、统一调度、统一使用。补齐短板，形成合力。强化队伍正规化、职业化、专业化建设，增强职业容易感，保持强大的战斗力。形成全覆盖的调度指挥平台，全面提高各安保企业综合能力，防范化解各类安全风险，资源共享，抢占应急科技创新高地'
        },
        {
          icon:'01_04.png',
          title: '全景多维度实时安保现场展现',
          info:'视角：全景、群组视角、第一视角。资源：安保人员，安保设备。事件：重点区域，事件热点，人力分布热力图，重点区域热力图。'
        },
        {
          icon:'01_05.png',
          title:'多群组，多优先级的实时语音对讲',
          info:'根据权限和事件紧急程度，实现群组内一对一对讲，群组实时广播对讲（多人会议模式），紧急呼叫直达顶层'
        },
        {
          icon:'01_06.png',
          title: '全场景多维度多媒体数据实时记录',
          info:'根据需要可记录整个安保过程的全媒体数据，并可随时复盘。'
        }
      ],
      productPurpose:[
        {
          icon:'02_01.png',
          title:'语音视频调度',
          info:'实现实时的文字聊天；实现客户端之间、客户端与服务器之间的文件传输，支持断点续传；支持透明通道，实现用户业务层自定义指令的加密传输，实时调度指挥。'
        },
        {
          icon:'02_02.png',
          title:'语音视频监控资源联动',
          info:'支持单方、双方以及多方音视频录制，实现本地、云端录制，实时监控。'
        },
        {
          icon:'02_03.png',
          title:'可视化呼叫',
          info:'采用优化后H.264视频编解码、AAC音频编码标准、码率低，压缩率高，视频更加清晰流畅，同时支持“一对一”、“一对多”以及“多对多”等多种模式的音频、视频交互。'
        },
        {
          icon:'02_04.png',
          title:'音视频回传分发',
          info:'通过应用程序实现数据的采集及个性化处理，之后再交由AnyChat对这些数据进行编码、传输等一系列的处理，实现AnyChat服务端、各客户端对音视频数据的互联互通'
        },
        {
          icon:'02_05.png',
          title:'文件分发',
          info:'支持“一对一”、“一对多”以及“多对多”等多种模式的文分发功能。'
        },
        {
          icon:'02_06.png',
          title:'GIS地图调度',
          info:'GIS调度服务器将GIS技术和多媒体指挥系统融合起来，提供基于位置信息的现场视频指挥、分组协同、人力调度、轨迹显示、人员定位、汇集回放等功能，提升限定条件下资源的利用效率。'
        },

      ],
      productDemo:[
        {
          icon:'03_01.png',
          title:'GIS调度',
          info:'与GIS相结合，可对执勤人员终端实现GPS定位于轨迹回放，支持地图终点选与圈的调度操作'
        },
        {
          icon:'03_02.png',
          title:'可视化调度',
          info:'全过程实现可视化调度控制与可视化管理，支持对讲终端的集群调度、视频调度功能，视频监控、一键视频等视频调度功能\n'
        },
        {
          icon:'03_03.png',
          title: 'APP视频',
          info:'集群对讲、一对一双向音频视频通话、视频实时回传到调度台、多媒体文本上传、位置上传、地图视图、IM富媒体文件上传\n'
        },
        {
          icon:'03_04.png',
          title: '安保企业视角',
          info:'上级领导可以直接指挥项目负责人，最高权限者可以随时远程指挥安保人员，点对点调度'
        },
        {
          icon:'03_05.png',
          title: '客户单位视角',
          info:'目标透明，进度可视；执勤人员数量；执勤人员在岗状态；执勤人员属性；点对点调度'
        },
        {
          icon:'03_06.png',
          title: '公共管理机构',
          info:'紧急调度；安全检查；线索支持'
        }
      ]
    }
  },
  created () {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.wrap {
  max-width: 1180px;
  margin: 0 auto;
  padding-bottom: 56px;

  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
  }
}

/*.banner {
  overflow: hidden;
  position: relative;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}*/
.banner {
  width: 100%;
  position: relative;

  img {
    width: 100%
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .wrap_in {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #FFFFFF;

      .p1 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .p2 {
        font-size: 46px;
        margin-bottom: 30px;
      }

      .p3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.wrap_dp_01 {
  min-height: 435px;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  overflow: hidden;
  display: flex;

  .left {
    float: left;
    min-height: 300px;
    width: calc(100% - 640px);
    padding-right: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    span {
      font-size: 18px;
      margin-bottom: 14px;
      line-height: 22px;
    }

    h1 {
      color: #000;
      font-size: 48px;
      line-height: 60px;
    }

    p {
      font-size: 16px;
      color: #575b62;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 40px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .btn_wrap {
      padding-top: 10px;
      display: flex;
      align-items: center;

      a {
        display: inline-block;
        height: 48px;
        padding: 0 30px;
        color: $themeColor;
        border: 1px solid $themeColor;
        font-size: 16px;
        margin-right: 20px;
        text-align: center;
        line-height: 48px;
        border-radius: 5px;

        &.active {
          background: $themeColor;
          color: #FFFFFF;
        }
      }
    }
  }

  .right {
    float: right;
    width: 640px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      min-height: 300px;
    }
  }
}

.wrap_dp_02 {
  h2 {
    font-size: 30px;
    color: #20242b;
    padding-top: 40px;
    font-weight: 400;
    height: 50px;
    border-bottom: #e6e6e6 1px solid;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 30px;
    li{
      box-sizing: border-box;
      width: 32%;
      margin-bottom: 25px;
      border: #ddd solid 1px;
      margin-right: 2%;
      &:nth-child(3n){
        margin-right: 0;
      }
      .img_wrap{
        background: #E7EEF8;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 0;
        padding-bottom: 60%;
        position: relative;
        &:hover{
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0.5);
            z-index: 5;
          }
          img{
            animation: changeScale 1s;
          }
        }
        img{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          object-fit: cover;
        }
      }
      .font_wrap{
        width: 100%;
        padding: 32px;
        h3{
          font-size: 18px;
          color: #383838;
          margin-bottom: 12px;
          &:hover{color: $themeColor}
        }
        p{
          font-size: 15px;
          color: #6e6e6e;
          line-height: 28px;
        }
      }
    }
  }
}

</style>
